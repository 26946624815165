import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from '@/pages/Home';
import Index from '@/pages/Index';
import Search from '@/pages/Search';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Buy from '@/pages/Buy';
import Course from '@/pages/Course';
import Content from '@/pages/Content';
import Admin from '@/pages/Admin';
import Users from '@/pages/Users';
import Orders from '@/pages/Orders';
import AdminLogin from '@/pages/AdminLogin';
import Record from '@/pages/Record';

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (location,res,rej) {
  if(res&&rej){
    originPush.call(this,location,res,rej)
  }else{
    originPush.call(this,location,()=>{},()=>{})
  }
}

VueRouter.prototype.replace = function (location,res,rej) {
  if(res&&rej){
    originReplace.call(this,location,res,rej)
  }else{
    originReplace.call(this,location,()=>{},()=>{})
  }
}

export default new VueRouter({
  routes: [
    {
        path: '/home',
        component: Home,
    },
    {
      path: '/',
      name: 'index',
      component: Index,
      meta:{show:true}
  },
    {
        path: '/index',
        name: 'index',
        component: Index,
        meta:{show:true}
    },
    {
        path: '/search/:searchKeyWord',
        component: Search,
        name: 'search'
    },
    {
        path: '/login',
        component: Login,
        meta: { hideHeader: true, hideFooter: true },
    },
    {
      path: '/record',
      name: 'record',
      component: Record,
  },
    {
        path: '/register',
        component: Register,
        meta: { hideHeader: true , hideFooter: true},
    },
    {
      path: '/buy',
      component: Buy,
      // meta: { hideHeader: true }
    },
    {
      path: '/course',
      component: Course,
      name: 'Course',
      // meta: { hideHeader: true }
    },
    {
      path: '/content/:id',
      component: Content,
      name: 'Content',
      // meta: { hideHeader: true }
    },
    {
      path: '/admin',
      component: Admin,
      name: 'Admin',
      meta: { hideHeader: true, hideFooter: true }
    },
    {
      path: '/users',
      component: Users, // 用户管理页面
      name: 'Users',
      meta: { hideHeader: true, hideFooter: true }
      // meta: { requiresAuth: true }
    },
    {
      path: '/orders',
      component: Orders, // 订单管理页面
      name: 'Orders',
      meta: { hideHeader: true, hideFooter: true }
      // meta: { requiresAuth: true}
    },
    {
      path: '/adminLogin',
      component: AdminLogin, // 订单管理页面
      name: 'AdminLogin',
      meta: { hideHeader: true, hideFooter: true }
      // meta: { requiresAuth: true}
    },
    {
        path: '*',
        redirect: '/index'
    }
  ]
});

// 添加路由守卫
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('jwt'); // 从 localStorage 获取 JWT

//   // 检查目标路由是否需要身份验证
//   if (to.matched.some(record => record.meta.requiresAuth) && !token) {
//     next({ path: '/login' }); // 如果没有 token，重定向到登录页面
//   } else {
//     next(); // 继续导航
//   }
// });