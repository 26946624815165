
<template>
  <div id="app">
    <Header v-if="!shouldHideHeader"></Header>
    <router-view :key="$route.fullPath"></router-view>
    <Footer  v-if="!shouldHideFooter" />
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  computed: {
    shouldHideHeader() {
      // 检查当前路由的元数据
      return this.$route.meta.hideHeader;
    },
    shouldHideFooter() {
      // 检查当前路由的元数据
      return this.$route.meta.hideFooter;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
