<template>
  <header class="header">
    <div class="top">
      <div class="container">
        <div class="logoArea" @click="toIndex"></div>
        <div class="loginList" v-if="!username">
          <p>
            <router-link to="/login">登录</router-link>
            <router-link to="/register" class="register">免费注册</router-link>
          </p>
        </div>
        <!-- <div class="loginList" v-else>
          <p>欢迎, {{ username }}!</p>
          <a href="#" @click.prevent="logout">退出</a>
        </div> -->

        <div class="navigationList">
          <router-link
            to="/home"
            :class="{ active: activeLink === 'freeIndex' }"
            @click.native="setActiveLink('freeIndex')"
          >
            免费指标
          </router-link>
          <router-link
            to="/course?type=1"
            :class="{ active: activeLink === 'premiumIndex' }"
            @click.native="setActiveLink('premiumIndex')"
          >
            精品指标
          </router-link>
          <router-link
            to="/course?type=2"
            :class="{ active: activeLink === 'small' }"
            @click.native="setActiveLink('small')"
          >
            小作文
          </router-link>
          <router-link
            to="/course?type=3"
            :class="{ active: activeLink === 'vip', 'is-vip-course': true }"
            @click.native="setActiveLink('vip')"
          >
            VIP课程
          </router-link>
          <router-link
            to="/course?type=4"
            :class="{ active: activeLink === 'teacher' }"
            @click.native="setActiveLink('teacher')"
          >
            名师课程
          </router-link>
        </div>

        <div class="searchIcon" @click="showSearchDrawer = true">
          <i class="el-icon-search"></i>
        </div>

        <div class="typeList">
          <!-- <router-link to="/record" :class="{'active': activeLink === 'record'}" >购买记录</router-link>
          <router-link to="/buy">金币: {{ coinNumber }}</router-link>
          <router-link to="/buy">充值</router-link> -->
          <!-- <router-link to="/admin">Admin</router-link> -->
          <el-popover
            placement="bottom-start"
            title=""
            width="300"
            trigger="hover"
            @show="showInfo"
             v-if="hasLogin"
          >
            <div
              slot="reference"
              style="font-size: 18px; cursor: pointer"
              class="el-icon-s-custom"
            ></div>
            <div class="user-hadnler-wrap">
              <div>账号：{{ userInfo.userName }}</div>
              <el-divider></el-divider>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <router-link to="/buy">金币: {{ userInfo.coinNumber }}</router-link>
                <div>小作文时长: {{ userInfo.compositionTime }}天</div>
                <router-link
                  to="/record"
                  :class="{ active: activeLink === 'record' }"
                  >购买记录</router-link
                >
              </div>
              <el-divider></el-divider>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <router-link to="/buy">充值</router-link>
                <div>VIP时长: {{ userInfo.vipTime }}天</div>
                <!-- <router-link to="/admin"></router-link> -->
                <a href="#" @click.prevent="logout">退出</a>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="showSearchDrawer" title="搜索" width="50%">
      <div class="drawerSearchArea">
        <el-input v-model="searchKeyWord" placeholder="输入关键字回车" @keyup.enter.native="toSearch">
          <template slot="append" 
            ><el-button @click="toSearch" style="cursor: pointer;">搜索</el-button></template
          >
        </el-input>
      </div>
    </el-dialog>
  </header>
</template>

<script>
import { Dialog, Input, Button } from 'element-ui'
import {getHomepageData} from '@/api/display'
import store from '@/store'
export default {
  name: 'Header',
  components: {
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  data() {
    return {
      searchKeyWord: '',
      showSearchDrawer: false,
      username: '',
      coinNumber: 0,
      hasLogin: false,
      compositionTime: 0 ,
      activeLink: 'freeIndex', // 默认值
      aMap: {
        1: 'premiumIndex',
        2: 'small',
        3: 'vip',
        4: 'teacher',
      },
      userInfo:{}
    }
  },
  computed: {
    count() {
      return this.$store.state.coinNumber
    },
    vipCount() {
      return this.$store.state.vipTime
    },
    hasLoginStatus() {
      return this.$store.state.hasLogin
    },
    // userInfo() {
    //   return this.$store.state.userInfo
    // },
  },
  watch: {
    count(newVal, oldVal) {
      this.coinNumber = newVal
      console.log(`Count changed from ${oldVal} to ${newVal}`)
    },
    vipCount(newVal, oldVal) {
      this.vipTime = newVal
      console.log(`Count changed from ${oldVal} to ${newVal}`)
    },
    hasLoginStatus(newVal, oldVal) {
      this.hasLogin = newVal
      console.log(`Count changed from ${oldVal} to ${newVal}`)
    },
    $route() {
      this.checkActiveLink()
    },
  },
  mounted() {
    this.loadUserData() // 加载用户数据
    this.checkActiveLink()
  },
  methods: {
    toIndex(){
      this.$router.replace({name: 'index'})
    },
    async showInfo(){
      let res = await getHomepageData()
      console.log('res', res)
      this.userInfo = res.data
    },
    loadUserData() {
      this.username = localStorage.getItem('username')
      this.coinNumber = localStorage.getItem('coinNumber')  ? parseInt(localStorage.getItem('coinNumber'), 10)
      : 0 // 从 localStorage 获取并转为数字
      this.hasLogin = localStorage.getItem('hasLogin') || localStorage.getItem('userid')
      this.vipTime = localStorage.getItem('vipTime') || 0;
      this.compositionTime = localStorage.getItem('compositionTime') || 0;
    },
    checkActiveLink() {
      // 检查当前路由名称
      if (this.$route.name === 'home') {
        this.activeLink = 'freeIndex'
      } else if (this.$route.name === 'course') {
        this.activeLink = this.aMap[this.$route.query.type] || 'premiumIndex'
      } else if (this.$route.name === 'record') {
        this.activeLink = 'record'
      } else {
        this.activeLink = ''
      }
    },
    setActiveLink(link) {
      this.activeLink = link // 更新激活状态
    },
    toSearch() {
      this.showSearchDrawer = false
      this.$router.push({
        name: 'search',
        params: { searchKeyWord: this.searchKeyWord },
      })
    },
    logout() {
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('coinNumber')
      localStorage.removeItem('jwt')
      store.commit('setCoin', 0)
      this.username = ''
      this.coinNumber = 0 // Reset coin number on logout
      this.$router.push('/login')
    },
    updateCoinNumber(newBalance) {
      this.coinNumber = newBalance // 更新余额
      localStorage.setItem('coinNumber', newBalance) // 更新 localStorage
    },
    onStorageChange(event) {
      console.log('onStorageChange', event)
      if (event.key === 'coinNumber') {
        this.coinNumber = parseInt(event.newValue, 10) || 0 // 更新组件中的 coinNumber
      }
    },
  },
}
</script>

<style scoped>
/* 保留原样的 CSS 样式 */
.header {
  background-color: #ffffff;
}
.user-hadnler-wrap {
  padding: 16px;
}
.top {
  height: 60px;
  border-bottom: 1px solid #ddd;
}
.logoArea {
  background-image: url('./logo.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 58px;
  display: block;
  overflow: hidden;
  width:120px;
  cursor: pointer;
}
.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigationList {
  display: flex;
  margin-left: -200px;
}

.navigationList a {
  margin: 20px 10px;
  font-size: 18px;
  color: #000; /* 默认字体颜色 */
  text-decoration: none; /* 无下划线 */
}

a.active {
  color: #007bff; /* 高亮颜色 */
  font-weight: bold; /* 加粗 */
}
a.is-vip-course {
  color: red;
}
.searchIcon {
  cursor: pointer;
  font-size: 24px;
  margin-right: 20px;
}

.typeList {
  display: flex;
}

.drawerSearchArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-input {
  font-size: 16px;
  padding: 5px;
  border-radius: 4px;
}

.loginList,
.typeList {
  display: flex;
  margin-left: -200px;
}

.loginList a,
.loginList p,
.typeList a {
  margin: 20px 10px;
  font-size: 18px;
}
</style>
