<template>
    <div class="footer">
      <div class="footer-container">
        <div class="footerList">
          <div class="footerItem">
            <h4>购物指南</h4>
            <ul class="footerItemCon">
              <li>购物流程</li>
              <li>会员介绍</li>
              <li>常见问题</li>
              <li>购物指南</li>
            </ul>
          </div>
          <div class="footerItem">
            <h4>支付方式</h4>
            <ul class="footerItemCon">
              <li>在线支付</li>
            </ul>
          </div>
          <div class="footerItem">
            <h4>售后服务</h4>
            <ul class="footerItemCon">
              <li>售后政策</li>
              <li>价格保护</li>
              <li>退款说明</li>
              <li>返修/退换货</li>
              <li>取消订单</li>
            </ul>
          </div>
          <div class="footerItem">
            <h4>帮助中心</h4>
          </div>
        </div>
        <div class="copyright">
          <ul class="helpLink">
            <li>
              联系我们
              <span class="space"></span>
            </li>
            <li>
              关于我们
              <span class="space"></span>
            </li>
            <li>
              营销中心
              <span class="space"></span>
            </li>
            <li>
              友情链接
              <span class="space"></span>
            </li>
            <li>关于我们</li>
          </ul>
          <p>地址：北京市昌平区宏福科技园综合楼6层</p>
          <p>京ICP备19006430号</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Footer",
  };
  </script>
  
  <style lang="less" scoped>
  // 底部
  .footer {
    background-color: #eaeaea;
    .footer-container {
      width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
      .footerList {
        padding: 20px;
        border-bottom: 1px solid #e4e1e1;
        border-top: 1px solid #e4e1e1;
        overflow: hidden;
        padding-left: 40px;
        .footerItem {
          width: 16.6666667%;
          float: left;
          h4 {
            font-size: 14px;
          }
          .footerItemCon {
            li {
              line-height: 18px;
            }
          }
          &:last-child img {
            width: 121px;
          }
        }
      }
      .copyright {
        padding: 20px;
        .helpLink {
          text-align: center;
          li {
            display: inline;
            .space {
              border-left: 1px solid #666;
              width: 1px;
              height: 13px;
              background: #666;
              margin: 8px 10px;
            }
          }
        }
        p {
          margin: 10px 0;
          text-align: center;
        }
      }
    }
  }
  </style>
  