<template>
  <div>
    <img src="/static/nav.png" alt="充值图" class="header-image">

    <div class="card-list">
      <div class="card-item">
        <div class="card-title">课程推荐</div>
        <div class="">
          <div class="vip-course-name" v-for="item in vipCourse" @click="toDetail(item, 4)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-title">热门资源</div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 16px;">
          <div v-for="(item, index) in topNav"
            style="display: flex;flex-direction: column;align-items: center;cursor: pointer;" @click="toUrl(item)">
            <img src="@/assets/icon/mianfei.svg" v-if="index === 0" style="width: 40px;height: 40px;" alt="">
            <img src="@/assets/icon/qiantianchong.svg" v-if="index === 1" style="width: 40px;height: 40px;" alt="">
            <img src="@/assets/icon/kecheng.svg" v-if="index === 2" style="width: 40px;height: 40px;" alt="">
            <img src="@/assets/icon/kecheng_1.svg" v-if="index === 3" style="width: 40px;height: 40px;" alt="">
            <div style="margin-top: 16px;">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-title">精品指标</div>
        <div style="text-align: left;">
          <el-tag v-for="item in jpCourse" type="info" size="mini" style="margin-bottom: 5px;" @click="toDetail(item, 4)"> {{ item.title }}</el-tag>
        </div>
      </div>
    </div>

    <div class="mocat mocats" id="mocats-6358">
      <div class="container">
        <div class="molis clearfix">
          <div class="moli">
            <!-- style="background: url(https://tdxgsw-1317643644.cos.ap-beijing.myqcloud.com/2024/06/20240616034058171.webp);" -->
            <div class="moli-header">
              <h3>名师课程</h3>
            </div>
            <ul>
              <li v-for="(item, index) in courses4"><i>{{ index + 1 }}</i><a :href="`/#/content/${item.id}`" target=""
                  style="color:#ff0000">{{ item.title }}</a>
              </li>
            </ul>
          </div>
          <div class="moli">
            <!-- style="background: url(https://tdxgsw-1317643644.cos.ap-beijing.myqcloud.com/2024/06/20240616034058171.webp);" -->
            <div class="moli-header">
              <h3>免费指标</h3>
            </div>
            <ul>
              <li v-for="(item, index) in courses"><i>{{ index + 1 }}</i><a :href="`/#/content/${item.id}`" target=""
                  style="color:#ff0000">{{ item.title }}</a>
              </li>
            </ul>
          </div>
          <div class="moli">
            <!-- style="background: url(https://tdxgsw-1317643644.cos.ap-beijing.myqcloud.com/2024/06/20240616034058171.webp);" -->
            <div class="moli-header">
              <h3>精品指标</h3>
            </div>
            <ul>
              <li v-for="(item, index) in courses1"><i>{{ index + 1 }}</i><a :href="`/#/content/${item.id}`" target=""
                  style="color:#ff0000">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="course-wrap">
      <div class="course-list">
        <el-card class="course-card" v-for="(res, index) in paginatedCourses" :key="res.id">
          <div class="course-box" @click="goToContentPage(res.id, res.price)">
            <img
              :src="res.url || 'https://tse3-mm.cn.bing.net/th/id/OIP-C.mIFhOinyZIi0CThCeqWF5AHaEJ?w=268&h=180&c=7&r=0&o=5&dpr=2&pid=1.7'"
              alt="课程图片" class="course-image" />
            <h3 class="course-title">{{ res.title }}</h3>
            <p style="display: flex; align-items: center; justify-content: space-between;">{{ res.createTime }} <span
                class="course-price">¥{{ res.price }}</span></p>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { CourseDataDisplay } from '@/api/display'
export default {
  name: '',
  data() {
    return {
      courses: [],
      courses1: [],
      courses4: [],
      paginatedCourses: [],
      vipCourse: [],
      jpCourse: [],
      topNav: [{ name: '免费指标', url: '/#/home' }, { name: '付费指标', url: '/#/course?type=3' }, { name: '小作文', url: '/#/course?type=2' }, { name: '名师课程', url: '/#/course?type=4' },]
    }
  },
  async mounted() {
    try {
      const response = await CourseDataDisplay({
        page: 1,
        pageSize: 10,
        type: 0,
        searchType: 0
      }); // 使用请求参数
      const response1 = await CourseDataDisplay({
        page: 1,
        pageSize: 10,
        type: 1,
        searchType: 1
      }); // 使用请求参数
      const response4 = await CourseDataDisplay({
        page: 1,
        pageSize: 10,
        type: 4,
        searchType: 1
      }); // 使用请求参数
      let res = await CourseDataDisplay({
        serchType: 3, type: 1, page: 1,
        pageSize: 100,
      })
      const vipRes = await CourseDataDisplay({
        page: 1,
        pageSize: 10,
        type: 3
      }); // 使用请求参数
      let jpRes = await CourseDataDisplay({
        serchType: 2, type: 0, page: 1,
        pageSize: 4
      })
      this.jpCourse = jpRes.data.records
      this.vipCourse = vipRes.data.records.slice(0, 4)
      console.log('res*****1', this.vipCourse)
      this.paginatedCourses = res.data.records
      this.courses = response.data.records; // 更新课程数据
      this.courses1 = response1.data.records; // 更新课程数据
      this.courses4 = response4.data.records; // 更新课程数据
      console.log('xxxx', this.courses)
    } catch (error) {
      console.error('获取课程数据时出错:', error);
    }
  },
  methods: {
    goToContentPage(id, price) {
      console.log(id, price);
      this.$router.push({ name: 'Content', params: { id: id } });
    },
    toDetail(item) {
      this.$router.push({ name: 'Content', params: { id: item.id } });
    },
    toUrl(item) {
      location.href = item.url
    }
  }
}
</script>

<style scoped lang="less">
.card-list {
  width: 1200px;
  margin: 16px auto;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .card-item {
    width: 0;
    flex: 1;
    border: 1px solid #efefef;
    padding: 14px 17px 0;
    box-sizing: border-box;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    height: 160px;
  }

  .card-title {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 1px;
    border-bottom: 1px solid #efefef;
    padding: 0 0 10px;
    margin-bottom: 14px;
    color: #383838;
    text-align: left;
  }

  .vip-course-name {
    text-align: left;
    line-height: 20px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 20px;
    cursor: pointer;
    margin-bottom: 5px;

  }

  .vip-course-name:hover {
    color: #e1251b;
  }
}

.course-wrap {
  width: 1200px;
  margin: 0 auto;
}

.course-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 32px;
  /* 使course-card水平居中 */

  .course-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: calc(25% - 20px);
    /* 让每行显示四个卡片，并考虑gap */
    box-sizing: border-box;
    text-align: center;
    transition: transform 0.2s;
    /* 过渡效果 */
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      /* 悬停时微微上浮 */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      /* 增强阴影效果 */
    }

    .course-image {
      height: 240px;
      width: 240px;
      object-fit: cover;
      border-radius: 8px;
      /* 图片圆角 */
      margin-bottom: 10px;
      /* 图片与标题间距 */
    }

    .course-title {
      text-align: left;
      font-size: 18px;
      /* 增大课程标题字体 */
      color: #333;
      /* 标题颜色 */
      margin: 10px 0;
      /* 上下间距 */
    }

    .course-price {
      font-size: 16px;
      /* 课程价格字体 */
      color: #e1251b;
      /* 价格色 */
    }
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.mocat {
  padding: 40px 0 25px;
  background: #fff;
  --theme-color: #ff5f33;
  --theme-color2: #ff5f33;
  --theme-radius: 5px;
}

.mocat:first-child {
  margin-top: -10px
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 1200px
}

.mocats .moli {
  width: calc(33.3333% - 20px);
  float: left;
  margin: 0 10px 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
}

.mocats .moli .moli-header {
  padding: 18px 20px;
  text-align: center;
  /* background-color: var(--theme-color); */
  color: #34435c;
  border-radius: var(--theme-radius) var(--theme-radius) 0 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.mocats .moli .moli-header h3 {
  font-weight: normal;
  font-size: 18px;
  z-index: 10;
  position: relative;
  font-weight: bold;
}

.mocats .moli ul {
  padding: 20px 15px;
}

.mocats .moli ul li {
  margin-bottom: 10px;
  padding: 0 26px 0 26px;
  position: relative;
}

.mocats .moli ul li:last-child {
  margin-bottom: 0
}

.mocats .moli ul a {
  display: block;
  line-height: 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 20px;
}

.mocats .moli ul span {
  position: absolute;
  right: 0;
  top: -1px;
  color: #777;
  font-size: 12px;
  width: 55px;
  height: 20px;
  overflow: hidden;
}

.mocats .moli ul span .icon {
  font-size: 14px;
}

.mocats .moli ul li>i {
  position: absolute;
  left: 0;
  top: 3px;
  background: #ccc;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-style: normal;
}

.mocats .moli ul li:first-child>i {
  background: var(--theme-color);
}

.mocats .moli ul li:nth-child(2)>i {
  background: var(--theme-color);
  opacity: .8
}

.mocats .moli ul li:nth-child(3)>i {
  background: var(--theme-color);
  opacity: .6
}

.post .post-sign,
.article-header .post-sign {
  background: #ff9600;
  color: #fff;
  padding: 0px 4px;
  display: inline-block;
  border-radius: 5px 0 5px 0;
  font-size: 12px;
  margin-right: 5px;
  line-height: 18px;
  position: relative;
  top: -1px;
  font-weight: normal;
}

@media (max-width: 1230px) {
  .container {
    max-width: 990px !important
  }
}
</style>