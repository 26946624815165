/*
 * @Author: 陈浪 1025518502@qq.com
 * @Date: 2024-09-29 21:37:17
 * @LastEditors: 陈浪 1025518502@qq.com
 * @LastEditTime: 2024-09-30 01:41:33
 * @FilePath: \csh\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'; // 引入axios
import router from "@/router";
const baseURL = '/api'; // 使用代理后的相对路径

// 创建一个新的axios实例
const service = axios.create({
    baseURL: baseURL,
    timeout: 20000 // 设置超时时间，超过该时间就不会发起请求
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        console.log('config',config)
        const token = localStorage.getItem('jwt'); // 确保读取的名字与登录时存储一致
        if (token) {
            config.headers['token'] = `${token}`;
        }
        config.headers['Content-Type'] = 'application/json'; // 根据需要设置内容类型
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        console.log('response',response)
        if (response.status === 200) {
            return response.data; // 返回数据部分，不返回完整响应
        } else {
            if(!['/user/homepage','/user/boutique/page', '/user/homepage/page'].includes(response.config.url)){
                router.push("/Login")
            }
            return Promise.reject(new Error('请求失败，状态码：' + response.status));
        }
    },
    error => {
        if (error.response) {
            console.error('请求失败，状态码：', error.response.status);
        } else if (error.request) {
            console.error('请求没有收到响应：', error.request);
        } else {
            console.error('请求配置错误：', error.message);
        }
        router.push("/Login")
        return Promise.reject(error);
    }
);

// 对外暴露
export default service;