<template>
    <div>
        <img src="/static/nav.png" alt="充值图" class="header-image">
        <div class="record-list">
            <el-table :data="tableData" pagination  style="width: 100%">
                <el-table-column prop="title" label="课程名称" />
                <el-table-column prop="price" label="课程价格" width="180" />
                <el-table-column prop="createTime" label="课程购买时间" />
                <!-- <el-table-column prop="action" label="操作">
                    <template slot-scope="scope">
                        <el-button link type="text" @click="toDetail(scope.row)">
                            查看课程详情
                        </el-button>
                    </template>
                </el-table-column> -->

            </el-table>
            <div class="table-page">
                <el-pagination  @current-change="handleChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        :pager-count="5"
                        background  layout="prev, pager, next" :total="total" />
            </div>
        </div>
    </div>
</template>

<script>
import {getRecordList} from '@/api/display'
    export default {
        data(){
            return {
                tableData:[],
                currentPage: 1,
                total: 0,
                pageSize: 20,
            }
        },
        mounted(){
            this.geList()
        },
        methods:{
            geList (){
                getRecordList({
                page:  this.currentPage,
                pageSize: this.pageSize
            }).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
                console.log('this.tableData',res, this.tableData)
            })
            },
            toDetail(row){
                this.$router.push(`/content/${row.id}`)
            },
            // 页面改变时的处理函数
        async handleChange(page) {
            this.currentPage = page; // 更新当前页码
            this.geList(); // 请求当前页的数据
        },
        }
    }
</script>

<style  scoped>
    .table-page {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
    .header-image {
        width: 1200px;
        margin: 0 auto;
    }
    .record-list {
        width: 1200px;
        margin: 16px auto;
    }
</style>