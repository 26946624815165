<template>
  <div style="margin: 50px; margin-top: 20px; margin-right: 100px">
    <!-- 按钮 -->
    <el-row>
      <el-button type="primary" @click="$router.push('/admin')"
        >课程
      </el-button>
      <el-button @click="$router.push('/orders')">订单 </el-button>
      <el-button @click="$router.push('/users')">用户 </el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-button style="float: right" type="primary" @click="openDialog"
        >+ 新增课程
      </el-button>
      <el-select
        v-model="courseType"
        placeholder="Select"
        size="large"
        style="width: 240px; float: left"
        @change="typeChange"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-row>
    <br />

    <!-- 数据表格 -->
    <el-table
      highlight-current-row
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
    >
      <el-table-column
        type="index"
        width="100"
        label="序号"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="price"
        width="100"
        label="价格"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="课程名称"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="最后操作时间"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="420"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="selectById(scope.row.id)"
            >编辑
          </el-button>
          <el-button
            size="mini"
            type="danger"
            plain
            @click="confirmDelete(scope.row.id)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新建对话框 -->
    <el-dialog title="保存课程" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="dept">
        <el-form-item label="课程名称" :label-width="formLabelWidth">
          <el-input
            v-model="dept.title"
            placeholder="请输入课程名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类型" :label-width="formLabelWidth">
          <el-select
            v-model="dept.type"
            placeholder="请选择课程类型"
            size="large"
            style="width: 100%; float: left"
            @change="typeChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="课程价格" :label-width="formLabelWidth">
          <el-input
            v-model="dept.price"
            placeholder="请输入课程价格"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程内容" :label-width="formLabelWidth">
          <quill-editor
            v-model="dept.content"
            ref="myQuillEditor"
            :options="editorOptions"
            @change="onEditorChange"
          ></quill-editor>

          <el-upload
            style="display: none"
            class="quill-picture-uploader"
            :action="uploadPath"
            :headers="uploadHeaders"
            :before-upload="bfUpload"
            :on-success="uploadSuccess"
            multiple
          >
          </el-upload>
        </el-form-item>
        <el-form-item label="标题图片" :label-width="formLabelWidth">
          <el-upload
            v-if="!imgPath"
            class="upload-demo"
            drag
            :action="uploadPath"
            :on-success="handleAvatarSuccess"
            :headers="uploadHeaders"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击或拖拽文件到此区域上传</div>
          </el-upload>
          <div v-if="imgPath">
            <div style="position: relative; width: 100px; margin: 0 auto">
              <i
                class="el-icon-error"
                style="position: absolute; right: 0px; cursor: pointer"
                @click="close"
              ></i>
              <img
                :src="dept.titleUrl"
                alt="课程图片"
                style="width: 100px; margin-top: 10px"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="链接" :label-width="formLabelWidth">
          <el-input
            v-model="dept.link"
            placeholder="请输入课程链接"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="提取码" :label-width="formLabelWidth">
          <el-input
            v-model="dept.code"
            placeholder="请输入提取码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :total="totalCourses"
        :pager-count="5"
        background
        layout="prev, pager, next, slot"
        @current-change="handleChange"
      />
    </div>
  </div>
</template>

<script>
import {
  DataDisplay1,
  CourseDataDisplay1,
  createContent,
  updateContent,
  getCourse,
  deleteContent,
  uploadHeader,
} from '@/api/display'
import { quillEditor } from 'vue-quill-editor' // 引入富文本编辑器组件
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  computed: {
    http() {
      return http
    },
    uploadHeaders() {
      let token = localStorage.getItem('authorization')
      // config.headers['token'] = `${token}`;
      // config.headers['authentication']= `${token}`;
      // config.headers['admin-token-name'] = `${token}`;
      return {
        token,
        authentication: token,
        'admin-token-name': token,
      }
    },
  },
  components: {
    quillEditor, // 注册富文本编辑器
  },
  data() {
    return {
      courseType: 0,
      options: [
        { label: '免费课程', value: 0 },
        { label: '付费课程', value: 1 },
        { label: '小作文', value: 2 },
        { label: 'VIP课程', value: 3 },
        { label: '名师课程', value: 4 },
      ],
      titleT: '',
      loading: false,
      imgName: '',
      imgPath: false,
      uploadPath: '/api/admin/common/upload',
      dialogFormVisible: false, // 对话框显示状态
      dept: {
        title: '',
        price: '',
        content: '',
        link: '',
        code: '',
        titleUrl: '',
      }, // 当前课程对象
      tableData: [], // 课程数据
      formLabelWidth: '100px', // Label宽度
      currentPage: 1, // 当前页码
      pageSize: 15, // 每页显示的课程数量
      totalCourses: 0, // 总课程数量
      editorOptions: {
        placeholder: '请输入课程内容',
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline'],
              ['link', 'image'],
              ['clean'], // 删除格式按钮
            ], //自定义工具栏，略
            handlers: {
              image: function (value) {
                //替换原图片上传功能
                // QuillWatch.emit(this.quill.id)
                if (value) {
                  document
                    .querySelector('.quill-picture-uploader input')
                    .click() //核心
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
          // toolbar: [
          //     [{header: [1, 2, false]}],
          //     ['bold', 'italic', 'underline'],
          //     ['link', 'image'],
          //     ['clean'] // 删除格式按钮
          // ]
        },
      },
    }
  },
  methods: {
    //上传图片之前校验
    bfUpload(file) {
      // console.log(file)
      // if ("image/png" == file.type || "image/jpeg" == file.type) {
      // } else {
      //     this.$message.error('图片插入失败,请检查文件格式');
      //     return;
      // }
    },
    //正文插入图片上传成功调用
    uploadSuccess(response, file, fileList) {
      this.dept.contentUrl = response.data
      console.log(this.dept.contentUrl, 'this.dept.contentUrl')
      // this.urlList.push(response.url);
      let quill = this.$refs.myQuillEditor.quill
      if (response.data != null) {
        //获取光标所在位置
        let length = quill.getSelection().index
        //插入图片
        quill.insertEmbed(length, 'image', response.data)
        //移动光标到图片后
        quill.setSelection(length + 1)
      }
    },
    onEditorChange(event) {
      this.$emit('getEditorInfo', event)
    },
    handleAvatarSuccess(response, file, fileList) {
      this.dept.titleUrl = response.data
      this.imgPath = true
      this.imgName = file.name
    },
    close() {
      this.dept.titleUrl = ''
      this.imgPath = false
    },
    openDialog() {
      this.titleT = '新增'
      this.dialogFormVisible = true
      this.imgPath = false
      this.dept = {
        title: '',
        price: '',
        content: '',
        link: '',
        code: '',
        titleUrl: '',
      } // 重置课程数据
    },
    async handleChange(page) {
      this.currentPage = page // 更新当前页码
      await this.fetchCourses() // 请求当前页的数据
    },
    async fetchCourses() {
      try {
        const response = await CourseDataDisplay1({
          page: this.currentPage,
          pageSize: this.pageSize,
          type: this.courseType,
        })
        this.tableData = response.data.records // 更新课程数据
        this.totalCourses = response.data.total // 更新总课程数量
      } catch (error) {
        console.error('获取课程数据时出错:', error)
      }
    },
    add() {
      console.log(this.dept, 'dept')
      let type = ''
      if (this.titleT === '编辑') {
        type = 'put'
      } else {
        type = 'post'
      }
      if (this.dept.title && this.dept.type !== undefined) {
        // 确保课程名称不为空
        if (this.dept.link && this.dept.code) {
          let result = this.dept.link + '?pwd=' + this.dept.code
          this.dept.link = JSON.parse(JSON.stringify(result))
        }
        createContent(type, this.dept)
          .then(() => {
            this.$message.success('课程创建成功!')
            this.fetchCourses() // 重新获取课程数据
            this.dialogFormVisible = false // 关闭对话框
            this.dept = {
              title: '',
              price: '',
              content: '',
              link: '',
              code: '',
              titleUrl: '',
            } // 重置表单数据
            this.imgPath = false
          })
          .catch((error) => {
            console.error('创建课程时出错:', error)
            this.$message.error('课程创建失败，请重试')
          })
      } else {
        this.$message.error(
          this.dept.title ? '课程类型不能为空' : '课程名称不能为空'
        )
      }
    },
    async selectById(id) {
      try {
        const courseData = await getCourse(JSON.stringify(id))
        this.titleT = '编辑'
        //this.dept = courseData.data; // 填充课程信息
        this.dept.title = courseData.data.title
        this.dept.price = courseData.data.price
        this.dept.content = courseData.data.content
        this.dept.link = courseData.data.link
        this.dept.id = courseData.data.id
        this.dept.titleUrl = courseData.data.titleUrl
        this.dept.type = courseData.data.type || this.courseType
        if (this.dept.titleUrl) {
          this.imgPath = true
        }
        if (this.dept.link) {
          //如果this.dept.link中包含?pwd=，则分割字符串并获取?pwd=后面的值
          this.dept.code = this.dept.link.split('?pwd=')[1]
          this.$set(this.dept, 'code', this.dept.code)
          //如果this.dept.link中包含?pwd=，则分割字符串并获取?pwd=前面的值
          this.dept.link = this.dept.link.split('?pwd=')[0]

          this.$set(this.dept, 'link', this.dept.link)
        }
        this.dialogFormVisible = true // 显示对话框以供编辑
      } catch (error) {
        console.error('获取课程信息时出错:', error)
      }
    },
    async updateCourse() {
      try {
        await updateContent(this.dept.id, this.dept) // 更新课程
        this.$message.success('课程更新成功')
        this.fetchCourses() // 刷新课程列表
        this.dialogFormVisible = false // 关闭对话框
      } catch (error) {
        this.$message.error('课程更新失败')
      }
    },
    async confirmDelete(id) {
          // 显示对话框，要求用户输入密码
          this.$prompt('请输入密码以确认删除:', '警告', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPlaceholder: '请输入您的密码'
          }).then(({ value }) => {
              // 检查输入的密码是否正确
              if (value === '17347573806') { // 替换为实际的密码检查逻辑
                  this.DeleteContent(id);
              } else {
                  this.$message.error('密码错误，删除操作被取消');
              }
          }).catch(() => {
              this.$message.info('已取消删除');
          });
      },
    DeleteContent(id) {
      this.$confirm('确定要删除该内容吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            let arr = [id]
            await deleteContent(arr) // 删除课程
            this.$message.success('内容删除成功!')
            this.fetchCourses() // 刷新课程列表
          } catch (error) {
            console.error('删除内容时出错:', error)
            this.$message.error('内容删除失败，请重试')
          }
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    async handleFileChange(file, fileList) {
      if (file && file.raw) {
        try {
          const response = await uploadHeader(file.raw) // 上传课程图片
          this.dept.image = response.url // 更新课程图片
          this.$message.success('文件上传成功!')
        } catch (error) {
          this.$message.error(error.message)
        }
      }
    },
    async typeChange() {
      this.fetchCourses()
    },
  },
  mounted() {
    this.fetchCourses() // 在组件挂载时获取课程数据
  },
}
</script>
<style></style>
