<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
  
      <div class="title-container">
        <h3 class="title">管理员系统登录</h3>
      </div>
  
      <el-form-item prop="phonenumber">
        <el-input
          ref="phonenumber"
          v-model="loginForm.phonenumber"
          placeholder="Phone Number"
          name="phonenumber"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>
  
      <el-form-item prop="captcha">
        <el-input
          ref="captcha"
          v-model="loginForm.captcha"
          placeholder="Captcha"
          name="captcha"
          type="text"
          tabindex="2"
          auto-complete="on"
        >
        <template slot="append"
                        ><div @click.prevent="sendCaptcha" style="cursor: pointer;">
                          {{ codeButtonText }}
                        </div></template
                      >
        </el-input>
        <!-- <el-button @click="sendCaptcha" type="primary">{{codeButtonText}}</el-button> -->
      </el-form-item>
  
      <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>
  
    </el-form>
  </div>
</template>

<script>
import { adminLogin, sendSms } from '@/api/display'; // 导入API

export default {
  name: 'AdminLogin',
  data() {
    return {
      loginForm: {
        phonenumber: '',
        captcha: ''
      },
      timer: null,
      codeButtonText: '发送验证码',
      loginRules: {
        phonenumber: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      loading: false
    }
  },
  
  methods: {
    // 发送验证码
    async sendCaptcha() {
      try {
        if(!this.loginForm.phonenumber) {
          return this.$message.error('请输入手机号')
        }
        if (!/^1\d{10}$/.test(this.loginForm.phonenumber)) {
          this.$message.error('手机号格式不正确')
          return
        }
        if(this.codeButtonDisabled) return;
        await sendSms(this.loginForm.phonenumber);
        this.$message.success('验证码已发送');
        this.codeButtonDisabled = true
        let timeLeft = 60
        this.timer = setInterval(() => {
          if (timeLeft === 0) {
            clearInterval(this.timer)
            this.timer = null
            this.codeButtonDisabled = false
            this.codeButtonText = '获取验证码'
          } else {
            this.codeButtonText = `验证码已发送 ${timeLeft}秒`
            timeLeft--
          }
        }, 1000)
      } catch (error) {
        this.$message.error(error.message);
      }
    },

    // 登录方法
    async handleLogin() {
      console.log(1);
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          console.log(2);
          try {
            console.log(3);
            const result = await adminLogin(this.loginForm.phonenumber, this.loginForm.captcha);
            console.log('登录返回的响应:', result);

            // 登录成功处理
            if (result.code === 1) {
              // 存储用户信息
              console.log(4);
              localStorage.setItem('authorization', result.data.token); // 存储 JWT
              // 跳转到 /admin 页面
              this.$router.push('/Admin');
            } else if (result.data.code === 404) { // 假设 404 代表账号不存在
              this.$message.error('账号不存在，请确认您的手机号');
            } else {
              this.$message.error(result.data.message); // 显示失败信息
            }
          } catch (error) {
            console.log(5);
            this.$message.error(error.message); // 显示其他错误信息
          } finally {
            this.loading = false;
          }
        } else {
          console.log('表单验证失败');
        }
      });
    }
  }
}
</script>

<style scoped>
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: gray; /* 请根据实际颜色替换为具体值 */
  overflow: hidden;
}

.login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
}

.title-container {
  position: relative;
}

.title {
  font-size: 26px;
  color: gray; /* 请根据实际颜色替换为具体值 */
  margin: 0 auto 40px auto;
  text-align: center;
  font-weight: bold;
  font-family: '楷体';
}
</style>
