<template>
    <div class="course-page">
    
        <div class="container">
            <div class="navbar">
                <img src="/static/nav.png" alt="Logo" class="logo" />
            </div>

            <div class="course-list">
                <el-card class="course-card" v-for="(res, index) in paginatedCourses" :key="res.id">
                    <div class="course-box" @click="goToContentPage(res.id, res.price)">
                        <img
                            :src="res.url || 'https://tse3-mm.cn.bing.net/th/id/OIP-C.mIFhOinyZIi0CThCeqWF5AHaEJ?w=268&h=180&c=7&r=0&o=5&dpr=2&pid=1.7'"
                            alt="课程图片" class="course-image"
                        />
                        <h3 class="course-title">{{ res.title }}</h3>
                        <p style="display: flex; align-items: center; justify-content: space-between;">{{res.createTime}}  <span class="course-price">¥{{ res.price }}</span></p>
                    </div>
                </el-card>
            </div>

            <el-pagination
                @current-change="handleChange"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="totalCourses"
                :pager-count="5"
                background
                layout="prev, pager, next, slot"
                class="pagination"
            ></el-pagination>
        </div>

        <div class="fixed-sidebar">
            <div class="hover-box" @mouseenter="showImage" @mouseleave="hideImage">
                <img v-if="isImageVisible" src="./ma.png" alt="悬停显示的图片" class="hover-image" />
                <img src="./vx.png" alt="静止显示的图片" class="static-image" />
            </div>
            <div class="back-to-top" @click="scrollToTop">
                <img src="./back.png" alt="返回顶部" class="static-image" />
            </div>
        </div>
    </div>
</template>

<script>
import { CourseDataDisplay } from '@/api/display';

export default {
    name: 'Course',
    data() {
        return {
            courses: [],          // 存储课程数据
            currentPage: 1,      // 当前页码
            pageSize: 12,        // 每页显示的课程数量
            totalCourses: 0,     // 总课程数量
            isImageVisible: false, // 控制悬停显示的图片
        };
    },
    computed: {
        // 计算当前页显示的课程
        paginatedCourses() {
            if (!Array.isArray(this.courses) || this.courses.length === 0) {
                return []; // 如果courses未定义或为空，返回一个空数组
            }
            return this.courses.slice(0, this.pageSize);
        },
    },
    async mounted() {
        await this.fetchCourses(); // 初次获取数据
    },
    methods: {
        // 页面改变时的处理函数
        async handleChange(page) {
            this.currentPage = page; // 更新当前页码
            await this.fetchCourses(); // 请求当前页的数据
        },
        // 获取课程数据
        async fetchCourses() {
            let userId = localStorage.getItem('userid')
            if(['2','3', '4'].includes(this.$route.query.type) && !userId) {
                this.$router.replace('/login');
                return
            }
            try {
                const response = await CourseDataDisplay({  
                    page: this.currentPage, 
                    pageSize: this.pageSize,
                    type: this.$route.query.type || 1
                }); // 使用请求参数
                this.courses = response.data.records; // 更新课程数据
                this.totalCourses = response.data.total; // 更新总课程数量
                console.log(this.courses);
                console.log(this.totalCourses);
            } catch (error) {
                console.error('获取课程数据时出错:', error);
            }
        },
        // 可点击课程跳转到内容页面
        goToContentPage(id, price) {
            console.log(id, price);
            this.$router.push({ name: 'Content', params: { id: id, price: price }});
        },
        // 显示图片
        showImage() {
            this.isImageVisible = true;
        },
        // 隐藏图片
        hideImage() {
            this.isImageVisible = false;
        },
        // 返回顶部
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
}
</script>

<style lang="less" scoped>
.course-page {
    border-bottom: 2px solid #e1251b;
    .course-box {
        cursor: pointer;
    }
    .container {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column; /* 设置为纵向排列 */
        background-color: #f7f7f7;
        padding: 20px; /* 增加内边距 */
        border-radius: 8px; 

        .navbar {
            background-color: #f79c41;
            color: white;
            padding: 15px;
            display: flex;
            justify-content: center; 
            border-radius: 5px;
            margin-bottom: 20px;

            .logo {
                width: 100%;
                height: auto;
            }
        }

        .course-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center; /* 使course-card水平居中 */

            .course-card {
                background-color: white;
                border-radius: 10px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                width: calc(25% - 20px); /* 让每行显示四个卡片，并考虑gap */
                box-sizing: border-box;
                text-align: center;
                transition: transform 0.2s; /* 过渡效果 */

                &:hover {
                    transform: translateY(-5px); /* 悬停时微微上浮 */
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增强阴影效果 */
                }

                .course-image {
                    height: 240px;
                    width: 240px;
                    object-fit: cover;
                    border-radius: 8px; /* 图片圆角 */
                    margin-bottom: 10px; /* 图片与标题间距 */
                }

                .course-title {
                    text-align: left;
                    font-size: 18px; /* 增大课程标题字体 */
                    color: #333; /* 标题颜色 */
                    margin: 10px 0; /* 上下间距 */
                }

                .course-price {
                    font-size: 16px; /* 课程价格字体 */
                    color: #e1251b; /* 价格色 */
                }
            }
        }

        .pagination {
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
    }

    .fixed-sidebar {
        position: fixed;
        right: 20px; /* 增加右边距以提升视觉效果 */
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 1000;

        .hover-box {
            width: 50px;
            height: 50px;
            margin-bottom: 10px; 
            cursor: pointer;
            position: relative;

            .hover-image {
                position: absolute;
                top: -60px; // 在上方显示
                left: -25px; // 中心对齐
                display: block;
                width: 120px; 
                height: auto; 
                z-index: 10;
            }

            .static-image {
                width: 100%;
                height: auto; 
            }
        }

        .back-to-top {
            width: 40px; 
            height: 40px; 
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .static-image {
                width: 100%; 
                height: auto; 
            }
        }
    }
}
</style>
