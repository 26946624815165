<template>
    <div class="type-nav">
        <div class="container">
            <div class="alert">
                如遇金币显示异常或充值长时间未变化，请刷新页面。详细可以咨询客服。
            </div>
            <img src="/static/nav.png" alt="充值图" class="header-image">
            
            <div class="item" v-for="(res, index) in paginatedCourses" :key="res.id">
                <div class="item-content" @click="goToContentPage(res.id)">
                    <div class="item-msg">{{ res.title }}</div>
                    <div class="minbox">
                        <span class="item-date">{{ res.createTime }}</span>
                        <span class="item-free">免费</span>
                    </div>
                </div>
            </div>
            <div class="pagination">
                <el-pagination
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="totalCourses"
                    :pager-count="5"
                    background
                    layout="prev, pager, next, slot"
                    @current-change="handleChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { CourseDataDisplay, getHomepageData } from '@/api/display';
import store from '@/store'
export default {
    name: "TypeNav",
    data() {
        return {
            courses: [],          // 存储课程数据
            currentPage: 1,      // 当前页码
            pageSize: 15,        // 每页显示的课程数量
            totalCourses: 0,     // 总课程数量
            coinNumber: 0        // 新增属性，用于存储 coinNumber
        };
    },
    computed: {
        // 计算当前页显示的课程
        paginatedCourses() {
            if (!Array.isArray(this.courses) || this.courses.length === 0) {
                return []; // 如果 courses 未定义或为空，返回一个空数组
            }
            return this.courses.slice(0, this.pageSize);
        },
    },
    async mounted() {
        await this.fetchCourses(); // 初次获取数据
        this.coinNumber = localStorage.getItem('coinNumber') || 150; // 从 localStorage 获取 coinNumber
        console.log('当前的 coinNumber:', this.coinNumber); // 输出 coinNumber 以验证
    },
    methods: {
        // 页面改变时的处理函数
        async handleChange(page) {
            this.currentPage = page; // 更新当前页码
            await this.fetchCourses(); // 请求当前页的数据
        },
        // 获取课程数据
        async fetchCourses() {
    try {
        const response = await CourseDataDisplay({  
            page: this.currentPage, 
            type: 0,
            pageSize: this.pageSize,
        });
        this.courses = response.data.records; // 更新课程数据
        this.totalCourses = response.data.total; // 更新总课程数量
        let id = localStorage.getItem('userid');
        if(id){
             // 获取 coinNumber
            const homepageResponse = await getHomepageData(); // 获取首页数据
            console.log('homepageResponse:', homepageResponse); // 打印整个响应以确认数据结构
                
            // 确保返回值中包含 coinNumber
            if (homepageResponse && homepageResponse.data) {
                const coinNumber = homepageResponse.data.coinNumber; // 获取 coinNumber
                console.log('coinNumber:', coinNumber);
                store.commit('setCoin', coinNumber);
                store.commit('setUserInfo', homepageResponse.data);
                store.commit('setVipTime', homepageResponse.data.vipTime);
                // 存储 coinNumber 到 localStorage
                localStorage.setItem('coinNumber', coinNumber);
                if(homepageResponse.data.compositionTime) {
                  localStorage.setItem('compositionTime', homepageResponse.data.compositionTime)
                }
                localStorage.setItem('vipTime', homepageResponse.data.vipTime);
            } else {
                console.error('获取首页数据时返回的数据结构不符合预期:', homepageResponse);
            }
        }
       

    } catch (error) {
        console.error('获取课程数据时出错:', error);
    }
},

        // 可点击课程跳转到内容页面
        goToContentPage(id) {
            this.$router.push({ name: 'Content', params: { id: id, price: -1 }});
        },
    }
};
</script>

<style lang="less" scoped>
* {
    box-sizing: border-box;
}

.type-nav {
    border-bottom: 2px solid #e1251b; 
    background-color: #fafafa; 
    padding-bottom: 20px; 

    .container {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column; 

        .alert {
    background: linear-gradient(to right, #4a90e2, #ff4081);
    color: white;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px; /* 增大提示字体 */
}

        .header-image {
            width: 100%; 
            margin-bottom: 20px; 
            border-radius: 8px; 
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
        }

        .item {
            display: flex;
            justify-content: space-between;
            padding: 30px 40px;
            border-bottom: 1px solid #e0e0e0;
            background-color: #ffffff;
            transition: background-color 0.3s;
            font-size: 20px;

            .item-content {
                display: flex;
                justify-content: space-between; /* 让内容在主轴上分散对齐 */
                width: 100%; /* 使其占满整个行 */
                flex-direction: column;
            }

            .minbox {
                display: flex;
                justify-content: space-between; /* 将日期和“免费”标签分开 */
                width: auto; /* 适应内容宽度 */
                align-items: center; /* 在交叉轴上居中对齐 */
            }

            .item-msg {
                flex: 1; /* 允许标题占用剩余空间 */
                margin-right: 10px; /* 与日期和“免费”标签的间距 */
                text-overflow: ellipsis; /* 溢出文本用省略号表示 */
                text-align: left;
            }

            .item-date {
                color: #9E9E9E; /* 灰色 */
                font-size: 16px;
                margin-right: 10px;
                
            }

            .item-free {
                color: #4CAF50; /* 绿色 */
                border: 1px solid #4CAF50;
                padding: 2px 6px;
                border-radius: 4px;
                font-size: 16px;
            }

            &:hover {
                background-color: #f5f5f5; /* 悬停效果 */
            }
        }

        .pagination {
            margin-top: 20px; 
            text-align: center; 
        }
    }
}
</style>
