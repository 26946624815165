<template>
  <div>
    <TypeNav/>
  </div>
</template>

<script>
export default {
  name: '',
}
</script>

<style>

</style>