/*
 * @Author: 陈浪 1025518502@qq.com
 * @Date: 2024-09-29 21:37:17
 * @LastEditors: 陈浪 1025518502@qq.com
 * @LastEditTime: 2024-10-07 11:18:12
 * @FilePath: \csh\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        coinNumber: 0,
        vipTime: 0,
        hasLogin: false,
        userInfo: {

        }
    },
    mutations: {
        setCoin(state, count) {
            localStorage.setItem('coinNumber', count);
            state.coinNumber = count;
        },
        setVipTime(state, count) {
            localStorage.setItem('vipTime', count);
            state.vipTime = count;
        },
        setLogin(state, count){
            localStorage.setItem('hasLogin', count);
            state.hasLogin = count;
        },
        setUserInfo (state, info){
            localStorage.setItem('userInfo', info);
            state.setUserInfo = info; 
        }
    },
    actions: {
       
    },
    getters: {
        coinNumber: state => state.coinNumber * 2
    }
});

export default store;