// 引入封装的request.js文件
import request from '@/utils/request'
import requ from "@/utils/requ";
import req from "@/utils/req";
import store from "@/store";


// 获取首页数据的函数
export const getHomepageData = async () => {
  try {
    let id = localStorage.getItem('userid');
      const response = await request.get('/user/homepage?userId='+id); // 发送 GET 请求

      return response; 
  } catch (error) {
      console.error('获取首页数据时出错:', error);
      // 抛出自定义错误信息，供调用的地方处理
      throw new Error(error.response?.data?.message || '获取首页数据失败');
  }
};

// 获取用户主页数据-首页
export const DataDisplay = async (params) => {
    try {
        console.log('Sending request with params:', params);
        const response = await request.get('/user/homepage/page', {
            params: params
        });
        console.log('Received response:', response);
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
// 获取用户购买记录
export const getRecordList = async (params) => {
  try {
      console.log('Sending request with params:', params);
      let id = localStorage.getItem('userid');
      params.id = id
      const response = await request.get('/record/page', {
          params: params
      });
      console.log('Received response:', response);
      return response;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
  }
};
// 封装/course/page请求-精品课程
export const CourseDataDisplay = async (params) => {
  try {
      // 发送 GET 请求
      const response = await request.get('/user/boutique/page', {
          params: params, // 传递请求参数
      });

      return response; // 返回响应中的数据部分
  } catch (error) {
      console.error('Error fetching course data:', error);
      throw new Error(error.response?.data?.message || '获取课程数据失败'); // 提供详细的错误信息
  }
};

// 获取用户主页数据-首页
export const DataDisplay1 = async (params) => {
  try {
      console.log('Sending request with params:', params);
      const response = await req.get('/admin/serch/free', {
          params: params
      });
      console.log('Received response:', response);
      return response;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
  }
};

// 封装/course/page请求-精品课程
export const CourseDataDisplay1 = async (params) => {
try {
    // 发送 GET 请求
    const response = await req.get('/admin/serch/boutique', {
        params: params, // 传递请求参数
    });

    return response; // 返回响应中的数据部分
} catch (error) {
    console.error('Error fetching course data:', error);
    // throw new Error(error.response?.data?.message || '获取课程数据失败'); // 提供详细的错误信息
}
};

export const RechargeData = async (orderData) => {
  try {
      // 解构赋值，获取 orderUserId 对象内部的 id、transactionId 和 amount
      const { id, transactionId, amount } = orderData.orderUserId;

      // 检查参数有效性
      if (!id) {
          throw new Error('订单用户 ID 不能为空');
      }

      const jwt = localStorage.getItem('jwt');
      if (!jwt) {
          throw new Error('用户未登录，无法进行充值');
      }

      // 准备请求参数
      const params = {
          orderUserId: id, // 使用用户 ID
          orderNumber: transactionId,
          money: amount,
      };

      const response = await request.post('/user/recharge', params, {
          headers: {
              'token': ` ${jwt}`  // 将 JWT 添加到请求头
          }
      });

      return response.data; // 返回响应中的数据部分
  } catch (error) {
      console.error('Error fetching recharge data:', error);
      throw new Error(error.response?.data?.message || '充值请求失败'); // 抛出更详细的错误信息
  }
};


// 封装购买功能

export const buyItem = async (id, coinNumber, userCoin) => {
  try {
    // 创建请求参数
    const params = {
      id: id,
      coinNumber: coinNumber,
      userCoin: userCoin
    };
    
    // 从 localStorage 获取 JWT
    const jwt = localStorage.getItem('jwt');
    
    const response = await request.put('/user/content/buy', params, {
      headers: {
        'token': ` ${jwt}`  // 将 JWT 添加到请求头
      }
    });
    store.commit('setCoin', (userCoin - coinNumber).toFixed(0));
    localStorage.setItem('coinNumber', (userCoin - coinNumber).toFixed(0));
    console.log('Received buy response:', response.data); // 打印购买响应以便调试
    return response; // 返回响应数据
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : "购买失败");
  }
};
// 封装 /user/content/{id} 接口
// 获取内容数据的函数
export const ContentDataDisplay = async (id, params) => {
  try {

      // 发送 GET 请求
      const response = await request.get(`/user/content/${id}`, {
          params, // 传递请求参数
      });

      return response; // 返回响应中的数据部分
      
  } catch (error) {
      console.error('Error fetching content data:', error.message || error);
      throw new Error(error.response?.data?.message || '获取内容数据失败'); // 提供更详细的错误信息
  }
};
export const ContentDataDisplay2 = async (params) => {
  try {

      // 发送 GET 请求
      const response = await request.get(`/user/content`, {
          params, // 传递请求参数
      });

      return response; // 返回响应中的数据部分
      
  } catch (error) {
      console.error('Error fetching content data:', error.message || error);
      throw new Error(error.response?.data?.message || '获取内容数据失败'); // 提供更详细的错误信息
  }
};
// 发送短信验证码的函数
export const sendSms = async (phonenumber) => {
  try {
    const response = await request.post('/user/user/register/sms', {
      phonenumber,
    });
    return response; // 返回响应数据

} catch (error) {
    throw new Error(error.response.data.message || "发送验证码失败");
}
};

  export const registerUser = async ( username,phonenumber, captcha, password) => {
    try {
      const response = await request.post('/user/user/register', {
        phonenumber,
        username,
        captcha,
        password
      });
      return response; // 返回响应数据
    } catch (error) {
      console.error('注册请求失败:', error);
      throw new Error(error.response.message || "注册失败");
    }
  };

// 密码登录功能封装
export const loginWithPassword = async (phonenumber, password) => {
    try {
      const response = await request.post('/user/user/login', { phonenumber, password });
      return response; // 返回响应数据
    } catch (error) {
      throw new Error(error.response.data.message || "登录失败");
    }
  };

// 验证码登录功能封装
export const loginWithCaptcha = async (phonenumber, captcha) => {
  try {
    const response = await request.post('/user/user/login/sms', { phonenumber, captcha });
    return response; // 返回响应数据
  } catch (error) {
    throw new Error(error.response.data.message || "登录失败");
  }
};
  //////////////////////////////////
 
  export function userFn(data) {
    return requ({
        url: '/api/admin/user',
        method: 'put',
        data
    })
}


export function createContent(type,data) {
    return requ({
        url: '/api/admin/content',
        method: type,
        data
    })
}
export function deleteContent(data) {
    return requ({
        url: '/api/admin/content?ids='+data,
        method: 'delete',
    })
}
export function getCourse(data) {
    return requ({
        url: '/api/admin/content/'+data,
        method: 'get',
    })
}

export function updateUserStatus(data) {
  return requ({
      url: '/api/admin/user/status',
      method: 'put',
      params: data
  })
}

export function getUsers(data) {
  return requ({
      url: '/api/admin/user/page',
      method: 'get',
      params: data
  })
}
 
  // 更新内容
  export const updateContent = async (id, updatedContent) => {
    await request.put(`/admin/content/${id}`, { content: updatedContent }); // 更新内容的 API
  };

 
  // id获取用户信息
  export const updateUser = async (id) => {
    await requ.get(`/api/admin/user/${id}`);
    return response; // 返回响应数据
  };

  // 修改用户信息
  export const changeUser = async (id, phonenumber,coinnumber,username,bought_lesson) => {
    await request.put(`/admin/user`, { id,phonenumber,coinnumber,username,bought_lesson });
  };
  
// 获取订单
export const getOrders = async (page, pageSize) => {
  const response = await requ.get('/api/admin/order/page', {
    params: {
      page,      // 当前页码
      pageSize   // 每页显示的数量
    }
  });
  return response;
};


   // 审核订单
   export const postOrders = async (pass,id) => {
    const response = await requ.post('/api/admin/order', { pass, id });
    return response;
  };

   // 管理员系统登录
   export const adminLogin = async (phonenumber, captcha) => {
    try {
      const response = await request.post('/admin/login', { phonenumber, captcha });
      return response; // 返回响应数据
    } catch (error) {
      throw new Error(error.response.data.message || "登录失败");
    }
  };

  // 封装文件上传功能
export const uploadHeader = async (file) => {
  try {
    const formData = new FormData(); // 创建 FormData 对象
    formData.append('file', file); // 将文件添加到 FormData 对象中

    const response = await request.post('/admin/common/uploadHeader', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
      },
    });

    console.log('文件上传成功:', response);
    return response; // 返回上传响应
  } catch (error) {
    console.error('文件上传失败:', error);
    throw new Error(error.response.data.message || "文件上传失败");
  }
};
