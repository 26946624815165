<template>
    <div class="login-container" style="margin: 50px; margin-top: 20px; margin-right: 100px">
      <el-row>
          <el-button
                  @click="$router.push('/admin')"
          >课程
          </el-button>
          <el-button
                  type="primary"
                  @click="$router.push('/orders')"
                  >订单
          </el-button>
          <el-button
                   @click="$router.push('/users')"
                  >用户
          </el-button>
      </el-row>
      <el-divider></el-divider>
      <div class="login-wrap">
        <!-- <div class="login-header">
          <router-link to="/Admin" class="home-link">指标管理 &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp</router-link>
          <span class="title">订单审核</span> &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp
          <router-link to="/Users" class="home-link">用户管理</router-link>
        </div> -->
        
        <!-- 数据表格 -->
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
        >
          <el-table-column type="index" width="100" label="序号" header-align="center" align="center"></el-table-column>
          <el-table-column prop="phonenumber" width="100" label="电话号" header-align="center" align="center"></el-table-column>
          <el-table-column prop="username" label="用户名称" header-align="center" align="center"></el-table-column>
          <el-table-column prop="money" label="充值金额" header-align="center" align="center"></el-table-column>
          <el-table-column prop="orderNumber" label="订单号" header-align="center" align="center"></el-table-column>
          <el-table-column prop="createTime" label="时间" header-align="center" align="center">
            <template v-slot="{ row }">
                {{ row.createTime ? row.createTime.replace('T',' ') : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="350" header-align="center" align="center">
            <template v-slot="{ row }">
              <el-button size="mini" type="primary" plain @click="selectById(row.id)">同意</el-button>
              <el-button size="mini" type="danger" plain @click="deleteById(row.id)">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalCourses"
            :pager-count="5"
            background
            layout="prev, pager, next, slot"
            @current-change="handleChange"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getOrders, postOrders } from '@/api/display'; // 根据实际路径调整
  
  export default {
    data() {
      return {
        tableData: [], // 订单数据数组
        currentPage: 1, // 当前页码
        pageSize: 15, // 每页显示的订单数量
        totalCourses: 0, // 总订单数量
      };
    },
    async mounted() {
      await this.fetchOrders(); // 在组件挂载时获取订单数据
    },
    methods: {
        async fetchOrders() {
  try {
    const response = await getOrders(this.currentPage, this.pageSize); // 传递当前页码和页大小
    this.tableData = response.data.records; // 更新订单数据
    this.totalCourses = response.data.total; // 更新总订单数量
    console.log('订单数据:', this.tableData); // 输出订单数据
    console.log('总订单数量:', this.totalCourses); // 输出总订单数量
  } catch (error) {
    console.error('获取订单数据时出错:', error);
  }
},

  
      async selectById(id) {
        try {
          await postOrders(1, id); // 审核通过订单
          this.$message.success("订单审核成功!");
          await this.fetchOrders(); // 刷新订单列表
        } catch (error) {
          this.$message.error("订单审核失败: " + error.message);
        }
      },
  
      async deleteById(id) {
        try {
          await postOrders(0, id); // 审核拒绝订单
          await this.fetchOrders(); // 刷新订单列表
          this.$message.success("订单已拒绝!");
        } catch (error) {
          this.$message.error("拒绝订单失败: " + error.message);
        }
      },
  
      async handleChange(page) {
        this.currentPage = page; // 更新当前页码
        await this.fetchOrders(); // 请求当前页的数据
      }
    }
  }
  </script>
  
  <style>
  /* 在此处添加样式 */
  </style>
  

  <style scoped>
  .login-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px; /* 增加内边距 */
      font-size: 24px; /* 增大字体 */
      background-color: #f8f8f8; /* 改变背景颜色以突出显示 */
      border-bottom: 1px solid #ddd; /* 添加下边框以增强分隔对比 */
  }
  
  .home-link {
      font-size: 20px; /* 增大链接字体 */
      color: #007bff;
      text-decoration: none; /* 去掉下划线 */
      transition: color 0.3s;
  
      &:hover {
          color: #0056b3; /* 悬停时改变链接颜色 */
      }
  }
  
  .title {
      font-weight: bold; /* 加粗标题文本 */
      color: #333; /* 改变标题颜色 */
  }
  </style>
