<template>
  <div class="register-container">
    <!-- 注册内容 -->
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
      <div class="register">
        <div class="register-header">
        <router-link to="/home" class="home-link"
          >返回首页 </router-link
        >
        <span class="title">注册</span>
        <span class="go"
          >我有账号，去
          <router-link to="/login" class="login-link"
            >登录</router-link
          ></span
        >
      </div>
        <el-divider></el-divider>
        <el-form-item prop="username" label="用户名">
          <el-input
            placeholder="请输入你的用户名"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <el-input
            placeholder="请输入你的手机号"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" label="验证码">
          <el-input placeholder="请输入验证码" v-model="form.code">
            <template slot="append"
              ><div @click.prevent="sendCode" style="cursor: pointer;">
                {{ codeButtonText }}
              </div></template
            >
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="登录密码">
          <el-input
            placeholder="请输入你的登录密码"
            type="password"
            v-model="form.password"
          ></el-input>
        </el-form-item>

        <div class="btn">
          <button @click.prevent="register">完成注册</button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { sendSms, registerUser, loginWithPassword } from '@/api/display'

export default {
  name: 'Register',
  data() {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1\d{10}$/
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    var validateName = (rule, value, callback) => {
      const phoneReg = /^[A-Za-z0-9]{3,15}$/
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback()
      }
    }
    return {
      phone: '',
      code: '',
      password: '',
      username: '', // 新增用户名
      timer: null,
      form: {
        phone: '',
        code: '',
        password: '',
        username: '', // 新增用户名
      },
      codeButtonText: '获取验证码',
      codeButtonDisabled: false,
      rules: {
        phone: [
          { required: true, message: '请输入你的手机号', trigger: ['blur', 'change'] },
          { validator: validatePhone, trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
          { min: 6, max: 6, message: '长度在6个字符', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '长度在6-20个字符', trigger: ['blur', 'change'] },
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: ['blur', 'change'] },
          { max: 10, message: '用户名不能超过10个字符', trigger: ['blur', 'change'] },
          { validator: validateName, trigger: ['blur', 'change'] },
        ],
      },
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async sendCode() {
      if (!/^1\d{10}$/.test(this.form.phone)) {
        this.$message.error('手机号格式不正确')
        return
      }
      if(this.timer)return
      try {
        console.log(this.form.phone)
        const response = await sendSms(this.form.phone) // 调用封装的发送短信函数
        console.log(response)
        this.$message.success('验证码已发送!')

        this.codeButtonDisabled = true
        let timeLeft = 60
        this.timer = setInterval(() => {
          if (timeLeft === 0) {
            clearInterval(this.timer)
            this.timer = null
            this.codeButtonDisabled = false
            this.codeButtonText = '获取验证码'
          } else {
            this.codeButtonText = `验证码已发送 ${timeLeft}秒`
            timeLeft--
          }
        }, 1000)
      } catch (error) {
        console.error('Error details:', error) // 打印出错误的完整信息
        throw new Error(error.response?.data?.message || '发送验证码失败')
      }
    },
    async register() {
      if (this.form.phone && this.form.code && this.form.password && this.form.username) {
        try {
          const response = await registerUser(
            this.form.username,
            this.form.phone,
            this.form.code,
            this.form.password
          ) // 将username传递

          console.log(response)
          if (response.code == 1) {
            this.$message.success('注册成功!', '自动登录中...')
            await this.login(this.form.phone, this.form.password)
          } else {
            this.$message.error(response.msg)
          }
        } catch (error) {
          console.error('Error details:', error) // 打印出错误的完整信息
        }
      } else {
        this.$message.warning('请输入完整的信息')
      }
    },
    async login(phone, password) {
      try {
        const response = await loginWithPassword(phone, password) // 使用登录函数
        console.log('Login response:', response)

        localStorage.setItem('username', response.data.username) // 存储username
        if(response.data.vipTime) {
          localStorage.setItem('vipTime', response.data.vipTime) // 存储username
        }
        if(response.data.compositionTime) {
            localStorage.setItem('compositionTime', response.data.compositionTime)
          }
        localStorage.setItem('jwt', response.data.token) // 存储 JWT
        localStorage.setItem('userid', response.data.id) // 存储 JWT
        // console.log('Login success!');
        // 登录成功后跳转到主页
        this.$router.push('/')
      } catch (error) {
        this.$message.error(error.message) // 显示错误信息
      }
    },
  },
}
</script>

<style lang="less" scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./images/register.png') no-repeat center center fixed;
  background-size: cover;
}

.register {
  width: 600px;
  padding: 20px 20px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
  box-sizing: border-box;
}
.register-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .home-link {
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #0056b3;
      }
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
    }

    .go {
      color: #555;

      .login-link {
        color: #007bff;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }
h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px; /* 增加字体大小 */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  margin-bottom: 20px;
  position: relative;
  margin-left: -100px;
}

label {
  width: 150px;
  text-align: right;
  display: inline-block;
}

input {
  width: 270px;
  height: 36px;
  padding-left: 8px;
  border: 1px solid #999;
  border-radius: 4px;
}

.invalid {
  border-color: red;
}

.error-msg {
  color: red;
  font-size: 12px;
  position: absolute; /* 使用绝对定位 */
  top: 40px; /* 根据需要调整显示位置 */
  left: 300px; /* 与输入框左侧对齐 */
}

.codeStyle {
  position: absolute; /* 使用绝对定位 */
  top: 10px; /* 根据需要调整显示位置 */
  left: 570px; /* 与输入框左侧对齐 */
}
.controls {
  text-align: center;
  margin: 20px 0;
}

.btn {
  text-align: center;
}

.btn button {
  width: 60%;
  padding: 10px;
  background: #e1251b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  padding-left: 8px;
  border: 1px solid #999;
  border-radius: 4px;
}
</style>
