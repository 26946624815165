<template>
    <div style="margin: 50px; margin-top: 20px; margin-right: 100px">
        <!-- 按钮 -->
        <el-row>
          <el-button
                  @click="$router.push('/admin')"
          >课程
          </el-button>
          <el-button
                  @click="$router.push('/orders')"
                  >订单
          </el-button>
          <el-button
                  type="primary"
                   @click="$router.push('/users')"
                  >用户
          </el-button>
      </el-row>
      <el-divider></el-divider>
        <el-row>
<!--            <el-button-->
<!--                    style="float: right"-->
<!--                    type="primary"-->
<!--                    @click="openDialog"-->
<!--            >+ 新增课程-->
<!--            </el-button>-->
<!--            <el-button-->
<!--                    style="float: left"-->
<!--                    type="primary"-->
<!--                    @click="ChangeCourse"-->
<!--            >切换课程-->
<!--            </el-button>-->
        </el-row>
        <br>

        <!-- 数据表格 -->
        <el-table
                highlight-current-row
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
        >
            <el-table-column type="index" width="100" label="序号" header-align="center"
                             align="center"></el-table-column>
            <el-table-column prop="username" width="120" label="用户名" header-align="center"
                             align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号" header-align="center" align="center"></el-table-column>
            <el-table-column prop="vipTime" label="Vip时长" header-align="center" align="center"></el-table-column>
            <el-table-column prop="compositionTime" label="小作文时长" header-align="center" align="center"></el-table-column>
            <el-table-column prop="coinNumber" label="金币数" header-align="center" align="center"></el-table-column>
            <!--          <el-table-column prop="updateTime" label="最后操作时间" header-align="center"-->
            <!--                           align="center"></el-table-column>-->
            <el-table-column label="操作" width="420" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="primary"
                            plain
                            @click="selectById(scope.row)"
                    >编辑
                    </el-button>
                    <el-button
                            size="mini"
                            :type="scope.row.status == 1 ? 'success' :'danger'"
                            plain
                            @click="DeleteContent(scope.row)"
                    >{{ scope.row.status == 1 ? '启用' : '禁用' }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 新建对话框 -->
        <el-dialog title="保存课程" :visible.sync="dialogFormVisible">
            <el-form :model="dept">
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input v-model="dept.username" placeholder="请输入课程名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input v-model="dept.phone" placeholder="请输入课程价格" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Vip时长" :label-width="formLabelWidth">
                    <el-input v-model="dept.vipTime" placeholder="请输入Vip时长" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="小作文时长" :label-width="formLabelWidth">
                    <el-input v-model="dept.compositionTime" placeholder="请输入小作文时长" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="金币" :label-width="formLabelWidth">
                    <el-input v-model="dept.coinNumber" placeholder="请输入金币" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </div>
        </el-dialog>

        <div class="pagination">
            <el-pagination
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="totalCourses"
                    :pager-count="5"
                    background
                    layout="prev, pager, next, slot"
                    @current-change="handleChange"
            />
        </div>
    </div>
</template>

<script>
import {
    DataDisplay,
    CourseDataDisplay,
    createContent,
    updateContent,
    getCourse,
    deleteContent,
    uploadHeader, getUsers, userFn, updateUserStatus
} from '@/api/display';
import {quillEditor} from 'vue-quill-editor'; // 引入富文本编辑器组件
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';


export default {
    computed: {
        http() {
            return http
        }
    },
    components: {
        quillEditor // 注册富文本编辑器
    },
    data() {
        return {
            titleT: '',
            loading: false,
            imgName: '',
            imgPath: false,
            uploadPath: '/api/admin/common/upload',
            changeCourse: true, // 免费课程标志
            dialogFormVisible: false, // 对话框显示状态
            dept: {
                username: '',
                phone: '',
                coinNumber: '',
            }, // 当前课程对象
            tableData: [], // 课程数据
            formLabelWidth: '100px', // Label宽度
            currentPage: 1, // 当前页码
            pageSize: 15, // 每页显示的课程数量
            totalCourses: 0, // 总课程数量
            editorOptions: {
                placeholder: '请输入课程内容',
                modules: {
                    toolbar: {
                        container: [
                            [{header: [1, 2, false]}],
                            ['bold', 'italic', 'underline'],
                            ['link', 'image'],
                            ['clean'] // 删除格式按钮
                        ],     //自定义工具栏，略
                        handlers: {
                            image: function (value) {    //替换原图片上传功能
                                if (value) {
                                    document.querySelector(".quill-picture-uploader input").click(); //核心
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                        }
                    }
                    // toolbar: [
                    //     [{header: [1, 2, false]}],
                    //     ['bold', 'italic', 'underline'],
                    //     ['link', 'image'],
                    //     ['clean'] // 删除格式按钮
                    // ]
                }
            },
        };
    },
    created() {
        this.fetchCourses(); // 请求当前页的数据
    },
    methods: {
        //上传图片之前校验
        bfUpload(file) {
            // console.log(file)
            // if ("image/png" == file.type || "image/jpeg" == file.type) {
            // } else {
            //     this.$message.error('图片插入失败,请检查文件格式');
            //     return;
            // }
        },
//正文插入图片上传成功调用
        uploadSuccess(response, file, fileList) {

            this.dept.contentUrl = response.data;
            console.log(this.dept.contentUrl, 'this.dept.contentUrl')
            // this.urlList.push(response.url);
            let quill = this.$refs.myQuillEditor.quill;
            if (response.data != null) {
                //获取光标所在位置
                let length = quill.getSelection().index;
                //插入图片
                quill.insertEmbed(length, 'image', response.data);
                //移动光标到图片后
                quill.setSelection(length + 1);
            }
        },
        onEditorChange(event) {

            this.$emit('getEditorInfo', event)
        },
        handleAvatarSuccess(response, file, fileList) {
            this.dept.titleUrl = response.data;
            this.imgPath = true;
            this.imgName = file.name;

        },
        close() {
            this.dept.titleUrl = "";
            this.imgPath = false;
        },
        openDialog() {
            this.titleT = '新增'
            this.dialogFormVisible = true;
            this.imgPath = false
            this.dept = {
                username: '',
                phone: '',
                coinNumber: '',
            }; // 重置课程数据
        },
        async handleChange(page) {
            this.currentPage = page; // 更新当前页码
            await this.fetchCourses(); // 请求当前页的数据
        },
        async fetchCourses() {
            getUsers({page: this.currentPage, pageSize: this.pageSize}).then(res => {
                this.tableData = res.data.records; // 更新课程数据
                this.totalCourses = res.data.total; // 更新总课程数量
            }).catch(err=>{
                this.$message.error('请求失败')
            })

            // try {
            //     console.log(this.currentPage,'currentPage')
            //     const response = await getUsers({
            //         page: this.currentPage,
            //         pageSize: this.pageSize
            //     });
            //     this.tableData = response.data.records; // 更新课程数据
            //     this.totalCourses = response.data.total; // 更新总课程数量
            // } catch (error) {
            //     console.error('获取课程数据时出错:', error);
            // }
        },
        add() {

            userFn(this.dept).then(() => {
                this.$message.success('课程创建成功!');
                this.fetchCourses(); // 重新获取课程数据
                this.dialogFormVisible = false; // 关闭对话框
                this.dept = {
                    username: '',
                    phone: '',
                    coinNumber: '',
                }; // 重置表单数据
                this.imgPath = false;
            }).catch(error => {
                console.error('创建课程时出错:', error);
                this.$message.error('课程创建失败，请重试');
            });
        },
        async selectById(row) {
            this.dept = JSON.parse(JSON.stringify(row))
            this.dialogFormVisible = true
        },
        async updateCourse() {
            try {
                await updateContent(this.dept.id, this.dept); // 更新课程
                this.$message.success("课程更新成功");
                this.fetchCourses(); // 刷新课程列表
                this.dialogFormVisible = false; // 关闭对话框
            } catch (error) {
                this.$message.error("课程更新失败");
            }
        },
        DeleteContent(row) {
            this.$confirm(`确定要${row.status == 1 ? '启用' : '禁用'}该内容吗?`, '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let status = row.status == 1 ? 0 : 1;
                updateUserStatus(
                    {status,id: row.id}
                ).then(() => {
                    this.$message.success('内容更新成功!');
                    this.fetchCourses(); // 刷新课程列表
                }).catch(err=>{
                    this.$message.error('请求失败')
                })
                // try {
                //     let arr = [id]
                //     await deleteContent(arr); // 删除课程
                //     this.$message.success('内容删除成功!');
                //     this.fetchCourses(); // 刷新课程列表
                // } catch (error) {
                //     console.error('删除内容时出错:', error);
                //     this.$message.error('内容删除失败，请重试');
                // }
            }).catch(() => {
                this.$message.info('已取消删除');
            });
        },
        async handleFileChange(file, fileList) {
            if (file && file.raw) {
                try {
                    const response = await uploadHeader(file.raw); // 上传课程图片
                    this.dept.image = response.url; // 更新课程图片
                    this.$message.success('文件上传成功!');
                } catch (error) {
                    this.$message.error(error.message);
                }
            }
        },
        ChangeCourse() {
            this.changeCourse = !this.changeCourse; // 切换课程类型
            this.fetchCourses();
        }
    },
    mounted() {
        //this.fetchCourses(); // 在组件挂载时获取课程数据
    }
}
</script>
<style>

</style>
