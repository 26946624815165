<template>
  <div class="login-container">
    <div class="login-wrap">
      <div class="login-header">
        <router-link to="/home" class="home-link"
          >返回首页 </router-link
        >
        <span class="title">登录</span>
        <span class="go"
          >我没有账号，去
          <router-link to="/register" class="login-link"
            >注册</router-link
          ></span
        >
      </div>
      <el-divider></el-divider>
      <div class="login">
        <div class="loginform">
          <div class="content">
            <el-form ref="ruleForm" :model="form" :rules="rules">
              <el-tabs v-model="loginType" @tab-click="tabChange">
                <el-tab-pane label="密码登录" name="password"></el-tab-pane>
                <el-tab-pane label="验证码登录" name="code"></el-tab-pane>
              </el-tabs>

              <!-- 使用手机号和密码登录 -->
              <div v-if="loginType === 'password'">
                <div class="input-text clearFix">
                  <el-form-item prop="phone">
                    <el-input
                      placeholder="请输入你的手机号"
                      v-model="form.phone"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="input-text clearFix">
                  <el-form-item prop="password">
                    <el-input
                      placeholder="请输入密码"
                      type="password"
                      v-model="form.password"
                    ></el-input>
                  </el-form-item>
                  <!-- <div class="error-msg">{{ errors.first("password") }}</div> -->
                </div>
              </div>

              <!-- 使用手机号和验证码登录 -->
              <div v-if="loginType === 'code'">
                <div class="input-text clearFix">
                  <el-form-item prop="phone">
                    <el-input
                      placeholder="请输入你的手机号"
                      v-model="form.phone"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="input-text clearFix">
                  <el-form-item prop="code">
                    <el-input placeholder="请输入验证码" v-model="form.code">
                      <template slot="append"
                        ><div @click.prevent="sendCode" style="cursor: pointer;">
                          {{ codeButtonText }}
                        </div></template
                      >
                    </el-input>
                  </el-form-item>
                  <!-- <span class="error-msg">{{ errors.first("code") }}</span> -->
                </div>
              </div>

              <button class="btn" type="submit" @click="login">
                登&nbsp;&nbsp;录
              </button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginWithPassword, sendSms, loginWithCaptcha } from '@/api/display' // 根据实际路径调整
import store from '@/store'
export default {
  name: 'Login',
  data() {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1\d{10}$/
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      loginType: 'password', // 默认使用密码登录
      codeButtonDisabled: false,
      codeButtonText: '获取验证码',
      timer: null,
      form: {
        phone: null,
        code: null,
        password: null,
      },
      rules: {
        phone: [
          { required: true, message: '请输入你的手机号', trigger: ['blur', 'change'] },
          { validator: validatePhone, trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
          { min: 6, max: 6, message: '长度在6个字符', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '长度在6-20个字符', trigger: ['blur', 'change'] },
        ],
      },
    }
  },
  methods: {
    async login() {
      this.$refs.ruleForm.validate(async (valid) => {
        console.log('ruleForm----1', valid)
        if (valid) {
          if (this.loginType === 'password') {
            try {
              const response = await loginWithPassword(
                this.form.phone,
                this.form.password
              )
              console.log('登录返回的响应:', response) // 输出响应内容
              if (!response.msg) {
                console.log('登录成功，token:', response.data.token) // 输出 token
                if(response.data.vipTime) {
                  localStorage.setItem('vipTime', response.data.vipTime)
                }
                if(response.data.compositionTime) {
                  localStorage.setItem('compositionTime', response.data.compositionTime)
                }
                // vipTime
                // 存储信息到 localStorage
                if (response.data.username) {
                  localStorage.setItem('username', response.data.username)
                }
                if (response.data.token) {
                  localStorage.setItem('jwt', response.data.token) // 存储 JWT
                }
                if (response.data.id) {
                  localStorage.setItem('userid', response.data.id) // 存储用户ID
                }

                this.$message.success('登录成功!')
                this.$router.push('/')
              } else {
                this.$message.error(response.msg) // 显示失败信息
              }
            } catch (error) {
              this.$message.error(error.message) // 显示其他错误信息
            }
          } else if (this.loginType === 'code') {
            // 验证手机号格式
            if (!/^1\d{10}$/.test(this.form.phone)) {
              this.$message.error('手机号格式不正确')
              return
            }

            if (!this.form.code) {
              this.$message.warning('请输入验证码')
              return
            }

            try {
              const response = await loginWithCaptcha(
                this.form.phone,
                this.form.code
              )

              if (!response.msg) {
                console.log('登录成功，token:', response.data.token) // 输出 token

                // 存储信息到 localStorage
                if (response.data.username) {
                  localStorage.setItem('username', response.data.username)
                }
                if(response.data.vipTime) {
                  localStorage.setItem('vipTime', response.data.vipTime)
                }
                if (response.data.token) {
                  localStorage.setItem('jwt', response.data.token) // 存储 JWT
                }
                if (response.data.id) {
                  localStorage.setItem('userid', response.data.id) // 存储用户ID
                }
                store.commit('setLogin', true)
                if (response.data.coinNumber) {
                  store.commit('setCoin', response.data.coinNumber)
                  localStorage.setItem('coinNumber', response.data.coinNumber)
                  console.log(
                    '登录成功，当前用户拥有',
                    response.data.coinNumber,
                    '个金币'
                  ) // 输出用户拥有的金币数量
                }
                this.$message.success('登录成功!')
                this.$router.push('/')
              } else {
                this.$message.error(response.msg) // 显示失败信息
              }
            } catch (error) {
              this.$message.error(error.message) // 显示错误信息
              console.error('登录过程中发生错误:', error) // 打印错误信息
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    tabChange(){
      console.log('tabChange')
      this.$refs.ruleForm.resetFields();
    },
    async sendCode() {
      if (!/^1\d{10}$/.test(this.form.phone)) {
        this.$message.error('手机号格式不正确')
        return
      }
      if(this.timer)return
      try {
        console.log(this.form.phone)
        const response = await sendSms(this.form.phone) // 调用封装的发送短信函数
        console.log(response)
        this.$message.success('验证码已发送!')

        this.codeButtonDisabled = true
        let timeLeft = 60
        this.timer = setInterval(() => {
          if (timeLeft === 0) {
            clearInterval(this.timer)
            this.timer = null
            this.codeButtonDisabled = false
            this.codeButtonText = '获取验证码'
          } else {
            this.codeButtonText = `验证码已发送 ${timeLeft}秒`
            timeLeft--
          }
        }, 1000)
      } catch (error) {
        console.error('Error details:', error) // 打印出错误的完整信息
        throw new Error(error.response?.data?.message || '发送验证码失败')
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer) // 在组件销毁时清除定时器
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-container {
  background: url('./images/login.png') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-wrap {
    width: 100%;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0;
    font-size: 18px;

    .home-link {
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #0056b3;
      }
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
    }

    .go {
      color: #555;

      .login-link {
        color: #007bff;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }

  .login {
    padding: 20px;
    padding-top: 0;
    .loginform {
      display: flex;
      flex-direction: column;
      .content {
        width: 100%;
        form {
          display: flex;
          flex-direction: column;

          .setting {
            display: flex;
            padding: 0 32px;
            justify-content: space-around; /* 两两分开 */
            align-items: center;
            font-size: 18px; /* 增大字体 */
            margin-bottom: 20px;

            .checkbox {
              display: flex;
              align-items: center;
            }

            .forget {
              color: #007bff;
              cursor: pointer;
              transition: color 0.3s;
              margin-left: auto; /* 将其放到最右侧 */

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .input-text {
            margin-bottom: 20px;
            .get-code-btn {
              position: relative;
              position: absolute;
              right: 10px;
              top: 10px;
              background-color: #e1251b;
              color: #fff;
              border: none;
              z-index: 2;
            }
            input {
              width: 60%; /* 使输入框宽度自适应 */
              height: 40px;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 0 10px;
              font-size: 16px;
              outline: none;
              transition: border-color 0.3s;

              &:focus {
                border-color: #e1251b;
              }
            }

            .error-msg {
              position: absolute;
              color: red;
              font-size: 12px;
              top: 50px;
              left: 120px;
            }
          }

          .btn {
            width: 100%; /* 使按钮宽度自适应 */
            margin: 10px auto;
            background-color: #e1251b;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px;
            font-size: 18px; /* 增大按钮字体 */
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #c1191a;
            }
          }
        }

        .call {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          .back,
          .register {
            color: #007bff;
            transition: color 0.3s;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
