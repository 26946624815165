/*
 * @Author: 陈浪 1025518502@qq.com
 * @Date: 2024-09-29 21:37:17
 * @LastEditors: 陈浪 1025518502@qq.com
 * @LastEditTime: 2024-09-29 22:05:22
 * @FilePath: \csh\src\utils\requ.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import router from "@/router";
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})

//req 拦截器
//可以自请求发送前对请求做一些处理
//比如统一加token，对请求参数做处理
service.interceptors.request.use(config => {
    let token =localStorage.getItem('authorization')
    if (token) {
        config.headers['token'] = `${token}`;
        config.headers['authentication']= `${token}`;
        config.headers['admin-token-name'] = `${token}`;
    }
    config.headers['Content-Type'] = 'application/json'; // 根据需要设置内容类型
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'; // 设置请求头
    // do something before req is sent
    return config;
}, error => {
    // do something with req error
    return Promise.reject(error);
});

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data; // 返回数据部分，不返回完整响应
        } else {
            router.push("/AdminLogin")
            return Promise.reject(new Error('请求失败，状态码：' + response.status));
        }
    },
    error => {
        console.log(error,'ffdd')
        if (error.response) {
            console.error('请求失败，状态码：', error.response.status);
        } else if (error.request) {
            console.error('请求没有收到响应：', error.request);
        } else {
            console.error('请求配置错误：', error.message);
        }
        router.push("/AdminLogin")
        return Promise.reject(error);
    }
);

export default service;