<template>
  <div class="buy-page">
    <img src="/static/nav.png" alt="充值图" class="header-image">
    
    <div class="recharge-container">
      <div class="account-info">
        <span>您的账号：<span class="account-number">{{ username }}</span></span>
      </div>
  
      <div class="input-group" v-if="!showFields">
        <label>选择充值金额：</label>
        <div class="radio-group">
          <label v-for="amount in amounts" :key="amount">
            <input type="radio" name="vip" :value="amount" v-model="selectedVip"> {{ amount }}金币
          </label>
        </div>
      </div>
  
      <div class="payment-info" v-if="selectedVip">
        <span>应支付：<span class="payment-amount">￥{{ paymentAmount }}元</span></span>
      </div>
  
      <div class="payment-methods">
        <img src="/static/vx.png" alt="微信支付" class="payment-icon">
        <span>微信支付</span>
      </div>
  
      <div class="payment-instructions">
        <span class="instruction-text">
          支付方式：请填写转账单号，点 <strong>“下一步”</strong> 后扫码付款，微信号：<strong style="color: red;">youzibajie</strong>
        </span>
      </div>

      <!-- 二维码展示部分 -->
      <div class="qr-code" v-if="qrCodeVisible">
        <img src="/static/ewm.jpg" alt="QR Code" class="qr-code-image">
      </div>
  
      <button class="next-button" @click="showInputFields" v-if="!showFields">下一步</button>
  
      <el-form label-position="left" label-width="100px" v-if="showFields" class="extra-info">
        <el-form-item  label="微信应支付"><el-input type="text" :value="paymentAmount" readonly >
          <template slot="append"
                        >元</template
                      >
                      </el-input></el-form-item>
                      <el-form-item   label="转账单号"><el-input type="text" v-model="transactionId" placeholder="交易单号" /></el-form-item>
        <button class="submit-button" @click="submitInfo">提交信息</button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { RechargeData } from '@/api/display'; // 导入RechargeData函数

export default {
  name: 'Buy',
  data() {
    return {
      username: '', // 存储用户名
      userid: '', // 存储用户ID
      selectedVip: null,
      paymentAmount: 0,
      amounts: [100, 300, 500, 1000, 2000],
      transactionId: '',  // 输入的转账单号
      showFields: false,  // 控制输入框的显示
      qrCodeVisible: false // 控制二维码的显示
    };
  },
  watch: {
    selectedVip(value) {
      this.paymentAmount = (value ? value / 1 : 0).toFixed(2);
    }
  },
  async created() {
    this.username = localStorage.getItem('username') || '用户'; // 如果没有设置，默认为 '用户'
    this.userid = localStorage.getItem('userid');
    console.log('用户名:', this.username);
    console.log('用户ID:', this.userid);
    if(!this.userid) {
      this.$router.push('/login');
    }
  },
  methods: {
    showInputFields() {
      if (!this.selectedVip) {
        this.$message.warning('请先选择一个VIP选项');
        return;
      }
      this.showFields = true;  // 显示输入框和提交按钮
      this.qrCodeVisible = true; // 显示二维码
    },
    
    async submitInfo() {
      if (!this.transactionId) {
        this.$message.warning('请填写转账单号');
        return;
      }

      try {
        // 调用RechargeData函数
        const response = await RechargeData({
      orderUserId: {
        id: this.userid,  // 用户ID
        transactionId: this.transactionId, // 交易单号
        amount: parseInt(this.paymentAmount) // 确保金额是整型
      }
    });
        
        console.log('充值响应:', response);
        this.$message.success('充值成功，请耐心等待管理员审核。');
      } catch (error) {
        this.$message.error('充值失败：' + (error.response ? error.response.data.message : error.message));
      }
    }
  }
}
</script>

<style lang="less" scoped>
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

.recharge-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.header-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
}

.account-info {
    font-size: 18px;
    margin-bottom: 20px;
}

.account-number {
    color: #ff3d3d;
    font-weight: bold;
}

.input-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.radio-group {
    display: flex;
    align-items: center;
}

.input-group label {
    margin: 16px;
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.payment-info {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
}

.payment-amount {
    color: #ff3d3d;
}

.payment-methods {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.payment-methods img {
    height: 35px;
    margin-right: 10px;
}

.payment-instructions {
    margin-top: 20px;
    font-size: 16px;
    color: #555;
    text-align: left;
    margin-bottom: 16px;
}

.qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.qr-code-image {
    max-width: 400px;
    height: auto;
}

.next-button {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.next-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.extra-info {
    margin-top: 20px;
}

.extra-info label {
    display: block;
    margin: 10px 0;
}

.submit-button {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}
</style>
