import Vue from 'vue'
import App from './App.vue'

//引入路由
import router from '@/router';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import TypeNav from '@/components/TypeNav'
Vue.component(TypeNav.name, TypeNav)

import store from '@/store';

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);
//表单验证
import "@/plugins/validate";

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
