<template>
  <div class="search-page">
    <div class="navbar">
      <img src="/static/nav.png" alt="Logo" class="logo" />
    </div>
    <div class="header">
      <el-input placeholder="搜索" class="search-input" @keyup.enter.native="handleDisplay" v-model="searchValue" />
      <el-button @click="handleDisplay">搜索</el-button>
    </div>
    <div class="filters">
      <div class="filter-category">
        <span>分类</span>
        <!-- <button :class="{ active: selectedType === '-1' }" @click="selectPrice('-1')">全部</button> -->
        <button :key="item.value" :class="{ active: selectedType === item.value }" v-for="item in options" @click="selectPrice(item.value)">
          {{ item.label }}
        </button>
      </div>
      <!-- <div class="filter-price">
        <span>分类</span>
        <button
          :class="{ active: selectedType === 'free' }"
          @click="selectPrice('free')"
        >
          免费指标
        </button>
        <button
          :class="{ active: selectedType === 'charged' }"
          @click="selectPrice('charged')"
        >
          收费
        </button>
      </div> -->
    </div>

    <div class="results">
      <div v-if="loading">加载中...</div>
      <div v-else-if="courses.length === 0">没有找到结果</div>
      <div v-else>
        <div class="course-list" v-if="[1, 2, 3].includes(selectedType)">
          <el-card
            class="course-card"
            v-for="(res, index) in paginatedCourses"
            :key="res.id"
          >
            <div class="course-box" @click="goToContentPage(res.id, res.price)">
              <img
                :src="
                  res.url ||
                  'https://tse3-mm.cn.bing.net/th/id/OIP-C.mIFhOinyZIi0CThCeqWF5AHaEJ?w=268&h=180&c=7&r=0&o=5&dpr=2&pid=1.7'
                "
                alt="课程图片"
                class="course-image"
              />
              <h3 class="course-title">{{ res.title }}</h3>
              <p class="course-price">{{
                  options.filter((item) => item.value == res.type)[0]?.label
                }} ¥{{ res.price }}</p>
            </div>
          </el-card>
        </div>
        <template v-if="selectedType === 0">
          <div
            class="item"
            v-for="(res, index) in paginatedCourses"
            :key="res.id"
          >
            <div class="item-content" @click="goToContentPage(res.id, 0)">
              <!-- 点击免费课程也展示内容 -->
              <span class="item-msg">{{ res.title }}</span>
              <div class="minbox">
                <span class="item-date">{{ res.createTime }}</span>
                <span class="item-free">{{
                  options.filter((item) => item.value == res.type)[0]?.label
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <el-pagination
          @current-change="handleChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalCourses"
          :pager-count="5"
          background
          layout="prev, pager, next, slot"
          class="pagination"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DataDisplay,
  CourseDataDisplay,
  ContentDataDisplay,
} from '@/api/display' // 导入API函数

export default {
  data() {
    return {
      searchValue: '',
      selectedType: 0,
      options: [
        { label: '免费课程', value: 0 },
        { label: '付费课程', value: 1 },
        { label: '小作文', value: 2 },
        { label: 'VIP课程', value: 3 },
        { label: '名师课程', value: 4 },
      ],
      courses: [],
      loading: false,
      currentPage: 1,
      pageSize: 15,
      totalCourses: 0,
    }
  },
  computed: {
    paginatedCourses() {
      if (!Array.isArray(this.courses) || this.courses.length === 0) {
        return [] // 如果courses未定义或为空，返回一个空数组
      }
      return this.courses.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      ) // 计算当前页课程
    },
  },
  async mounted() {
    this.searchValue = this.$route.params.searchKeyWord
    await this.handleDisplay() // 初次获取数据
  },
  methods: {
    async handleDisplay() {
      this.loading = true // 显示加载状态
      const params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        title: this.searchValue, // 将搜索值添加到请求参数
        type: this.selectedType
      }
      if(this.selectedType === '-1'){
        delete params.type
      }
      try {
        const response = await CourseDataDisplay(params) // 请求付费课程数据
        this.courses = response.data.records || [] // 更新课程数据
        this.totalCourses = response.data.total || 0 // 更新总课程数量
        // if (this.selectedType === 'free') {
        //   const response = await DataDisplay(params) // 请求免费课程数据
        //   this.courses = response.data.records || [] // 更新课程数据
        //   this.totalCourses = response.data.total || 0 // 更新总课程数量
        // } else if (this.selectedType === 'charged') {
        // }
      } catch (error) {
        console.error('Error occurred:', error) // 错误处理
        this.courses = []
        this.totalCourses = 0
      } finally {
        this.loading = false // 关闭加载状态
      }
    },

    selectPrice(price) {
      this.selectedType = price // 更新选择的价格类型
      this.currentPage = 1 // 重置到第一页
      this.handleDisplay() // 请求课程数据
    },

    handleChange(page) {
      this.currentPage = page // 更新当前页码
      this.handleDisplay() // 重新请求数据
    },

    // 新增方法：根据 ID 和价格跳转到内容页面
    async goToContentPage(id, price) {
      try {
        // 请求课程内容数据
        const contentData = await ContentDataDisplay(id) // 获取课程内容
        // 存储返回的数据或进行路由跳转
        // 例如，将内容数据存入 Vuex 状态或直接展示到新页面
        console.log('获取的课程内容:', contentData)
        this.$router.push({ name: 'Content', params: { id: id, price: price } }) // 路由跳转
      } catch (error) {
        console.error('获取内容数据失败:', error) // 打印错误
      }
    },
  },
}
</script>

<style scoped lang="less">
.course-box {
        cursor: pointer;
    }
.search-page {
  width: 1200px;
  margin: 0 auto;
  .navbar {
    background-color: #f79c41;
    color: white;
    padding: 15px;
    display: flex;
    width: 1200px;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 20px;

    .logo {
      width: 100%;
      height: auto;
    }
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    .search-input {
      width: 100%;
      max-width: 500px;
      padding: 5px;
    }

    .search-button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .filters {
    background-color: #f9f9f9;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
    font-size: 16px;

    .filter-category,
    .filter-price {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        margin-right: 30px;
      }

      button {
        margin-right: 10px;
        background-color: #ccc;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 5px;

        &.active {
          background-color: #ff5722;
        }
      }
    }
  }

  .results {
    margin: 20px auto;

    .item {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px;
      border-bottom: 1px solid #e0e0e0;
      background-color: #ffffff;
      transition: background-color 0.3s;
      font-size: 20px;

      .item-content {
        display: flex;
        justify-content: space-between; /* 让内容在主轴上分散对齐 */
        width: 100%; /* 使其占满整个行 */
        align-items: center; /* 在交叉轴上居中对齐 */
      }

      .minbox {
        display: flex;
        justify-content: space-between; /* 将日期和“免费”标签分开 */
        width: auto; /* 适应内容宽度 */
        align-items: center; /* 在交叉轴上居中对齐 */
      }

      .item-msg {
        flex: 1; /* 允许标题占用剩余空间 */
        margin-right: 10px; /* 与日期和“免费”标签的间距 */
        text-overflow: ellipsis; /* 溢出文本用省略号表示 */
        text-align: left;
      }

      .item-date {
        color: #9e9e9e; /* 灰色 */
        font-size: 16px;
        margin-right: 10px;
      }

      .item-free {
        color: #4caf50; /* 绿色 */
        border: 1px solid #4caf50;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 16px;
      }

      &:hover {
        background-color: #f5f5f5; /* 悬停效果 */
      }
    }

    .course-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center; /* 使course-card水平居中 */

      .course-card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        width: calc(25% - 20px); /* 让每行显示四个卡片，并考虑gap */
        padding: 15px; /* 增加内边距 */
        box-sizing: border-box;
        text-align: center;
        transition: transform 0.2s; /* 过渡效果 */

        &:hover {
          transform: translateY(-5px); /* 悬停时微微上浮 */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增强阴影效果 */
        }

        .course-image {
          height: 240px;
          width: 240px;
          object-fit: cover;
          border-radius: 8px; /* 图片圆角 */
          margin-bottom: 10px; /* 图片与标题间距 */
        }

        .course-title {
          font-size: 18px; /* 增大课程标题字体 */
          color: #333; /* 标题颜色 */
          margin: 10px 0; /* 上下间距 */
        }

        .course-price {
          font-size: 16px; /* 课程价格字体 */
          color: #e1251b; /* 价格色 */
        }
      }
    }

    .pagination {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
